/* eslint-disable max-len */

import { merge } from "lodash"
import errors from "./errors"

// If necessary, translations can be exploded to separate files:
// - Splitting by language or
// - Splitting by context (documents, users, common service, etc)

export default merge({}, errors, {
  fi: {
    "arialabel.aside-documentKeywords": "Dokumenttiin liittyvät asiasanat",
    "arialabel.description-documentKeywords": "Asiasana-linkkiä klikkaamalla pääset listaan kaikista siihen liittyvistä dokumenteista",
    "arialabel.common-names-and-abbreviations": "Laista on käytössä seuraavat lempinimet ja lyhenteet",
    "arialabel.documentToc-close": "Piilota alitasot",
    "arialabel.documentToc-open": "Näytä alitasot",
    "arialabel.materialJumpTo": "Siirry suoraan dokumenttiin",
    "arialabel.nav-breadcrumb": "Murupolku",
    "arialabel.nav-extra": "Muut sivut",
    "arialabel.nav-global": "Päänavigaatio",
    "arialabel.nav-languageSelector": "Kielivalinta",
    "arialabel.nav-letter": "Kirjainnavigaatio",
    "arialabel.nav-material": "Aineistonavigaatio",
    "arialabel.nav-pageFooterLinks": "Info-linkit",
    "arialabel.nav-toc": "Dokumentin sisällysluettelo",
    "arialabel.searchSuggestions": "Hakutulosehdotukset",
    "arialabel.sidebarDescription": "Aineiston kuvaus",
    "arialabel.updateInformation": "Aineiston päivitystieto",

    "language.english": "English",
    "language.finnish": "Suomi",
    "language.swedish": "Svenska",
    // Search filter languages
    "label.en": "Englanti",
    "label.fi": "Suomi",
    "label.sv": "Ruotsi",

    // Materials (used for navigation also)
    "label.administrativeCourtDecision": "Hallinto-oikeus",
    "label.committeeReport": "Valiokuntamietinnöt",
    "label.courtOfAppealDecision": "Hovioikeudet",
    "label.environmentBulletin": "EHS-Säädösseuranta",
    "label.euStatuteConsolidated": "EU-Lainsäädäntö",
    "label.governmentBill": "Hallituksen esitykset",
    "label.home": "Etusivu",
    "label.internationalTreatyConsolidated": "Valtiosopimukset",
    "label.insuranceCourtDecision": "Vakuutusoikeus",
    "label.labourCouncilStatement": "Työneuvoston lausunnot",
    "label.labourCourtDecision": "Työtuomioistuin",
    "label.newsArticle": "Uutiset",
    "label.statuteConsolidated": "Lainsäädäntö",
    "label.supremeAdministrativeCourtPrecedent": "Korkein hallinto-oikeus",
    "label.supremeCourtPrecedent": "Korkein-oikeus",
    "label.tyosuhdeasioidenKasikirja": "Työsuhdeasioiden Käsikirja",

    // Labels
    "label.accessibility": "Saavutettavuusseloste",
    "label.advancedSearch": "Tarkennettu haku",
    "label.all": "Kaikki",
    "label.allWords": "Kaikilla sanoilla",
    "label.anyWords": "Millä tahansa sanoilla",
    "label.archiveBrowse.description": "Selaa vuosihakemistoa vuosittain",
    "label.archiveBrowse": "Selaa vuosihakemistoa",
    "label.archiveNoYears": "Vuosihakemistoon ei löytynyt dokumenttien vuosia",
    "label.backtotop.desc": "Palaa dokumentin alkuun",
    "label.backtotop": "Alkuun",
    "label.close": "Sulje",
    "label.commonNamesAndAbbreviations": "Lempinimet ja lyhenteet",
    "label.contactInformation": "Yhteystiedot",
    "label.coop": "Yhteistyössä",
    "label.documentArticle": "Artikla",
    "label.documentChapter": "Luku",
    "label.documentNumber": "Numero",
    "label.documentSection": "Pykälä",
    "label.documentYear": "Vuosi",
    "label.exactPhrase": "Täsmällisellä fraasilla",
    "label.feedback.email": "Sähköposti",
    "label.feedback.message": "Viesti",
    "label.feedback.name": "Nimi",
    "label.feedback.submit": "Lähetä",
    "label.feedback": "Palaute",
    "label.feedbackPrefix": "Palaute:",
    "label.filter": "Suodata",
    "label.first.hit": "Ensimmäiseen osumaan",
    "label.first": "Ensimmäinen",
    "label.formErrors": "Syötteessä virheitä:",
    "label.hideSubjectNav": "Piilota aihealueet",
    "label.instructions": "Ohjeet",
    "label.itemsPerPage": "Tuloksia sivulla",
    "label.jumpto": "Siirry",
    "label.keywords.byLetter": "{letter}-kirjaimella alkavat asiasanat",
    "label.keywords": "Asiasanat",
    "label.languages": "Kielet",
    "label.last.hit": "Viimeiseen osumaan",
    "label.last": "Viimeinen",
    "label.login.external": "Kirjaudu sisään toisen palvelun kautta",
    "label.login": "Kirjaudu sisään",
    "label.logout": "Kirjaudu ulos",
    "label.material": "Aineisto",
    "label.more": "Lisää",
    "label.next.result": "Seuraava hakutulos",
    "label.next": "Seuraava",
    "label.noneWords": "Ilman sanoja",
    "label.password": "Salasana",
    "label.previous.result": "Edellinen hakutulos",
    "label.previous": "Edellinen",
    "label.print": "Tulosta",
    "label.privacyPolicy": "Tietosuojaseloste",
    "label.published": "Julkaistu",
    "label.relevance": "Relevanssi",
    "label.rememberMe": "Muista minut",
    "label.search": "Hae",
    "label.searchForm": "Hakulomake",
    "label.searchHits.hide": "Poista hakukorostus",
    "label.searchHits.show": "Näytä hakukorostus",
    "label.searchQuery": "Käyttämäsi hakutermit",
    "label.searchResultNumber": "Hakusi tuotti <strong>{n}</strong> osumaa. | Hakusi tuotti <strong>{n}</strong> osuman. | Hakusi tuotti <strong>{n}</strong> osumaa.",
    "label.searchResults.tooltip": "Takaisin hakutuloslistalle",
    "label.searchResults": "Hakutuloslista",
    "label.searchSort": "Järjestys",
    "label.searchTerm": "Hakusana",
    "label.showSubjectNav": "Näytä aihealueet",
    "label.statutes": "Säädökset",
    "label.subjects": "Aihealueet",
    "label.termsOfUse": "Käyttöehdot",
    "label.time": "Aika",
    "label.toc": "Sisällysluettelo",
    "label.username": "Käyttäjätunnus",

    "label.feedbackMessage.error.404": "Pyytämääsi sivua ei löytynyt. Tarkista sivuosoite ja jos virhetilanne toistuu, ota yhteyttä asiakaspalveluun.",
    "label.feedbackMessage.error": "Virhe",
    "label.feedbackMessage.info": "Info",
    "label.feedbackMessage.success": "Palaute",
    "label.feedbackMessage.warning": "Huom",

    "throbber.message": "Odota hetki, sisältöä ladataan…",

    "placeholder.allWords": "Hakusana",
    "placeholder.anyWords": "Hakusana",
    "placeholder.exactPhrase": "Hakusana",
    "placeholder.material": "Aineiston tunnus",
    "placeholder.noneWords": "Hakusana",
    "placeholder.password": "Salasana",
    "placeholder.username": "Käyttäjätunnus",

    "heading.ContactInfoOwner": "Palvelun omistajan yhteystiedot",
    "heading.ContactInfoService": "Palvelun yhteystiedot",
    "heading.PageFooterLinks": "Palvelun yleiset sivut",
    "heading.PageFooterOtherServices": "Muut palvelut",
    "heading.search": "Haku",
    "heading.searchResult": "Hakutulokset",

    "heading.administrativeCourtDecision.index": "Uusimmat ratkaisut",
    "heading.caseLaw.archive": "Vuosihakemisto",
    "heading.caseLaw.index": "Uusimmat",
    "heading.committeeReport.archive": "Vuosihakemisto",
    "heading.committeeReport.index": "Uusimmat Valiokuntamietinnöt",
    "heading.courtOfAppealDecision.index": "Uusimmat ratkaisut",
    "heading.environmentBulletin.archive": "Vuosihakemisto",
    "heading.environmentBulletin.frontpage": "Uusimmat EHS-tiedotteet",
    "heading.environmentBulletin.index": "Uusimmat EHS-tiedotteet",
    "heading.governmentBill.archive": "Vuosihakemisto",
    "heading.governmentBill.index": "Uusimmat hallituksen esitykset",
    "heading.governmentBill.jumpto": "Siirry esitykseen",
    "heading.insuranceCourtDecision.index": "Uusimmat ratkaisut",
    "heading.labourCouncilStatement.index": "Uusimmat Työneuvoston lausunnot",
    "heading.labourCourtDecision.index": "Uusimmat ratkaisut",
    "heading.lawDrafting.archive": "Vuosihakemisto",
    "heading.lawDrafting.index": "Uusimmat esityöt",
    "heading.newsArticle.archive": "Vuosihakemisto",
    "heading.newsArticle.frontpage": "Uusimmat uutiset",
    "heading.newsArticle.index": "Uusimmat uutiset",
    "heading.statuteAmendment.latest": "Uusimmat säädösmuutokset",
    "heading.statuteAmendment.frontpage": "Uusimmat säädösmuutokset",
    "heading.statuteConsolidated.commonNamesAndAbbreviations": "Säädösten lempinimet ja lyhenteet",
    "heading.statuteConsolidated.index": "Säädösluettelo",
    "heading.statuteConsolidated.jumpto": "Siirry säädökseen",
    "heading.supremeAdministrativeCourtPrecedent.index": "Uusimmat ratkaisut",
    "heading.supremeCourtPrecedent.index": "Uusimmat ratkaisut",
    "heading.tyosuhdeasioidenKasikirja.index": "Sisällysluettelo",

    "documentType.administrativeCourtDecision": "Hallinto-oikeus",
    "documentType.committeeReport": "Valiokuntamietinnöt",
    "documentType.courtOfAppealDecision": "Hovioikeus",
    "documentType.environmentBulletin": "EHS-tiedote",
    "documentType.euStatuteConsolidated": "EU-Säädös",
    "documentType.governmentBill": "Hallituksen esitys",
    "documentType.insuranceCourtDecision": "Vakuutusoikeus",
    "documentType.internationalTreatyConsolidated": "Valtiosopimus",
    "documentType.labourCouncilStatement": "Työneuvoston lausunnot",
    "documentType.labourCourtDecision": "Työtuomioistuin",
    "documentType.link": "Linkki",
    "documentType.newsArticle": "Uutinen",
    "documentType.statuteAmendment": "Säädösmuutos",
    "documentType.statuteConsolidated": "Säädös",
    "documentType.supremeAdministrativeCourtPrecedent": "Korkein hallinto-oikeus",
    "documentType.supremeCourtPrecedent": "Korkein-oikeus",
    "documentType.tyosuhdeasioidenKasikirja": "Työsuhdeasioiden Käsikirja",

    "documentActions.document-pdf-fi": "Suomeksi (PDF)",
    "documentActions.document-pdf-sv": "Ruotsiksi (PDF)",
    "documentActions.edilexReferenceCard": "Viitekortti Edilexissä",
    "documentActions.edilexStatuteAmendment": "Muutossäädös",
    "documentActions.finlexReferenceCard": "Viitekortti Finlexissä",
    "documentActions.lang-en": "Englanniksi",
    "documentActions.lang-fi": "Suomeksi",
    "documentActions.lang-sv": "Ruotsiksi",
    "documentActions.pdf": "PDF",
    "documentActions.print": "Tulosta",
    "documentActions.referenceCard": "Viitekortti",

    "title.documentActions.document-pdf": "Avaa PDF-dokumentti",
    "title.documentActions.document-pdf-fi": "Avaa suomenkielinen PDF-dokumentti",
    "title.documentActions.document-pdf-sv": "Avaa ruotsinkielinen PDF-dokumentti",
    "title.documentActions.lang-en": "Avaa englanninkielinen versio",
    "title.documentActions.lang-fi": "Avaa suomenkielinen versio",
    "title.documentActions.lang-sv": "Avaa ruotsinkielinen versio",
    "title.documentActions.pdf": "PDF",
    "title.documentActions.print": "Tulosta",
    "title.documentActions.reference-card": "Avaa viitekortti",
    "title.documentActions.statute-amendment": "Avaa muutossäädös",

    "materialGroup.caseLaw": "Oikeuskäytäntö",
    "materialGroup.committeeReport": "Valiokuntamietinnöt",
    "materialGroup.ehs": "EHS-säädösseuranta",
    "materialGroup.governmentBills": "Hallituksen esitykset",
    "materialGroup.lawDrafting": "Esityöt",
    "materialGroup.legislation": "Lainsäädäntö",
    "materialGroup.news": "Uutiset",
    "materialGroup.tyosuhdeasioidenKasikirja": "Työsuhdeasioiden Käsikirja",

    "description.abbreviations.statute-consolidated": "Säädösten epävirallisia lempinimiä ja lyhenteitä voi käyttää haussa",
    "description.feedback": "Otamme mielellämme vastaan palautetta, joka liittyy palvelun kehittämiseen ja toimivuuteen. Juridinen neuvonta ja tekstien sisällöllinen tulkinta eivät kuulu palvelun piiriin.",

    "keywords.documentCount": "Dokumentteja",
    "keywords.actualKeyword": "Ks.",
    "keywords.relatedKeyword": "Ks. myös",
  },
  sv: {
    "arialabel.aside-documentKeywords": "Dokumentets nyckelord",
    "arialabel.description-documentKeywords": "Välj ett nyckelord för att få en lista över alla dokument som är relaterade till det",
    "arialabel.common-names-and-abbreviations": "Följande etablerade författningsnamn och förkortningar används i lagen",
    "arialabel.documentToc-close": "Dölj undernivåer",
    "arialabel.documentToc-open": "Visa undernivåer",
    "arialabel.materialJumpTo": "Gå direkt till dokumentet",
    "arialabel.nav-breadcrumb": "Navigering med synlig sökväg",
    "arialabel.nav-extra": "Andra sidor",
    "arialabel.nav-global": "Huvudnavigering",
    "arialabel.nav-languageSelector": "Språkval",
    "arialabel.nav-letter": "Bokstavnavigering",
    "arialabel.nav-material": "Materialnavigering",
    "arialabel.nav-pageFooterLinks": "Info-linkit",
    "arialabel.nav-toc": "Innehållsförteckning för dokumentet",
    "arialabel.searchSuggestions": "Sökresultatförslag",
    "arialabel.sidebarDescription": "Materialbeskrivning",
    "arialabel.updateInformation": "Information om materialuppdatering",

    "language.english": "English",
    "language.finnish": "Suomi",
    "language.swedish": "Svenska",
    // Search filter languages
    "label.en": "Engelska",
    "label.fi": "Finska",
    "label.sv": "Svenska",

    "label.administrativeCourtDecision": "Förvaltningsdomstolen",
    "label.committeeReport": "Utskottsbetänkanden",
    "label.courtOfAppealDecision": "Hovrätter",
    "label.environmentBulletin": "EHS-Säädösseuranta",
    "label.euStatuteConsolidated": "EU-Lagstiftning",
    "label.governmentBill": "Regeringspropositioner",
    "label.home": "Förstasidan",
    "label.internationalTreatyConsolidated": "Fördrag",
    "label.insuranceCourtDecision": "Vakuutusoikeus",
    "label.labourCouncilStatement": "Työneuvoston lausunnot",
    "label.labourCourtDecision": "Työtuomioistuin",
    "label.newsArticle": "Nyheter",
    "label.statuteConsolidated": "Lagstiftning",
    "label.supremeAdministrativeCourtPrecedent": "Högsta förvaltningsdomstolen",
    "label.supremeCourtPrecedent": "Högsta domstolen",
    "label.tyosuhdeasioidenKasikirja": "Työsuhdeasioiden Käsikirja",

    // Labels
    "label.accessibility": "Tillgänglighetsutlåtande",
    "label.advancedSearch": "Avancerad sökning",
    "label.all": "Alla",
    "label.allWords": "Efter alla ord",
    "label.anyWords": "Efter något av ord",
    "label.archiveBrowse.description": "Bläddra i årsarkivet årsvis",
    "label.archiveBrowse": "Bläddra i årsarkivet",
    "label.archiveNoYears": "Ei dokumentteja vuosihakemistossa",
    "label.backtotop": "Till toppen",
    "label.backtotop.desc": "Tillbaka till toppen av dokumentet",
    "label.close": "Stäng",
    "label.commonNamesAndAbbreviations": "Etablerade författningsnamn och förkortningar",
    "label.contactInformation": "Kontaktinformation",
    "label.coop": "I samarbete med",
    "label.documentArticle": "Artikeln",
    "label.documentChapter": "Kapitel",
    "label.documentNumber": "Nummer",
    "label.documentSection": "Paragraf",
    "label.documentYear": "År",
    "label.exactPhrase": "Efter en specifik fras",
    "label.feedback.email": "Epost",
    "label.feedback.message": "Meddelande",
    "label.feedback.name": "Namn",
    "label.feedback.submit": "Skicka",
    "label.feedback": "Respons",
    "label.feedbackPrefix": "Respons:",
    "label.filter": "Filtera",
    "label.first.hit": "Till första träff",
    "label.first": "Första",
    "label.formErrors": "Fel i input:",
    "label.hideSubjectNav": "Dölj teman",
    "label.instructions": "Instruktioner",
    "label.itemsPerPage": "Resultat per sida",
    "label.jumpto": "Gå",
    "label.keywords.byLetter": "Nyckelord som börjar med bokstaven {letter}",
    "label.keywords": "Nyckelord",
    "label.languages": "Språk",
    "label.last.hit": "Till sista träff",
    "label.last": "Sista",
    "label.login.external": "Kirjaudu sisään toisen palvelun kautta",
    "label.login": "Logga in",
    "label.logout": "Logga ut",
    "label.material": "Material",
    "label.material.onlyDefaultLanguage": "Material finns endast på finska",
    "label.more": "Flera",
    "label.moveToDefaultLanguage": "Siirry suomenkieliseen aineistoon",
    "label.next.result": "Nästa sökresultat",
    "label.next": "Följande",
    "label.noneWords": "Utan ord",
    "label.password": "Lösenord",
    "label.previous.result": "Föregende sökresultat",
    "label.previous": "Föregående",
    "label.print": "Skriv ut",
    "label.privacyPolicy": "Registerbeskrivning",
    "label.published": "Publicerat",
    "label.relevance": "Relevans",
    "label.rememberMe": "Kom ihåg mig",
    "label.search": "Sök",
    "label.searchForm": "Sökfält",
    "label.searchHits.hide": "Visa utan markering",
    "label.searchHits.show": "Visa markering",
    "label.searchQuery": "Söktermer",
    "label.searchResultNumber": "Din sökning gav <strong>{n}</strong> resultat. | Din sökning gav <strong>{n}</strong> resultat. | Din sökning gav <strong>{n}</strong> resultat.",
    "label.searchResults": "Sökresultat",
    "label.searchResults.tooltip": "Tillbaka till sökresultat",
    "label.searchSort": "Ordningsföljd",
    "label.searchTerm": "Sökterm | Söktermer",
    "label.showSubjectNav": "Visa teman",
    "label.statutes": "Författningar",
    "label.subjects": "Ämnen",
    "label.termsOfUse": "Användningsvillkor",
    "label.time": "Tid",
    "label.toc": "Innehållsförteckning",
    "label.username": "Användarnamn",

    "label.feedbackMessage.error.404": "Sidan kan inte hittas. Kontrollera nätadressen, om problemet upprepas, kontakta kundservicen.",
    "label.feedbackMessage.error": "Fel",
    "label.feedbackMessage.info": "Info",
    "label.feedbackMessage.success": "Respons",
    "label.feedbackMessage.warning": "Obs.",

    "throbber.message": "Ett ögonblick, innehållet laddas…",

    "placeholder.allWords": "Sökterm",
    "placeholder.anyWords": "Sökterm",
    "placeholder.exactPhrase": "Sökterm",
    "placeholder.material": "Materialets beteckning",
    "placeholder.noneWords": "Sökterm",
    "placeholder.password": "Lösenord",
    "placeholder.username": "Användarnamn",

    "heading.ContactInfoOwner": "Kontaktuppgifter för ägaren av tjänsten",
    "heading.ContactInfoService": "Kontaktuppgifter för tjänsten",
    "heading.PageFooterLinks": "Allmän webbplats för tjänsten",
    "heading.PageFooterOtherServices": "Andra tjänster",
    "heading.search": "Sök",
    "heading.searchResult": "Sökresultat",

    "heading.administrativeCourtDecision.index": "Nyaste avgöranden",
    "heading.caseLaw.archive": "Årsarkiv",
    "heading.caseLaw.index": "Nyaste",
    "heading.committeeReport.archive": "Årsarkiv",
    "heading.committeeReport.index": "Nyaste utskottsbetänkanden",
    "heading.courtOfAppealDecision.index": "Nyaste avgöranden",
    "heading.environmentBulletin.archive": "Årsarkiv",
    "heading.environmentBulletin.frontpage": "Uusimmat EHS-tiedotteet",
    "heading.environmentBulletin.index": "Uusimmat EHS-tiedotteet",
    "heading.governmentBill.archive": "Årsarkiv",
    "heading.governmentBill.index": "Nyaste regeringspropositioner",
    "heading.governmentBill.jumpto": "Gå till propositionen",
    "heading.insuranceCourtDecision.index": "Nyaste avgöranden",
    "heading.labourCouncilStatement.index": "Uusimmat Työneuvoston lausunnot",
    "heading.labourCourtDecision.index": "Nyaste avgöranden",
    "heading.lawDrafting.archive": "Årsarkiv",
    "heading.lawDrafting.index": "Nyaste förarbeten",
    "heading.newsArticle.archive": "Årsarkiv",
    "heading.newsArticle.frontpage": "Nyaste nyheter",
    "heading.newsArticle.index": "Nyaste nyheter",
    "heading.statuteAmendment.latest": "Nyaste författningsändringar",
    "heading.statuteAmendment.frontpage": "Nyaste författningsändringar",
    "heading.statuteConsolidated.commonNamesAndAbbreviations": "Etablerade författningsnamn och förkortningar",
    "heading.statuteConsolidated.index": "Författningsförteckning",
    "heading.statuteConsolidated.jumpto": "Gå till författning",
    "heading.supremeAdministrativeCourtPrecedent.index": "Uusimmat ratkaisut",
    "heading.supremeCourtPrecedent.index": "Nyaste avgöranden",
    "heading.tyosuhdeasioidenKasikirja.index": "Innehållsförteckning",

    "documentType.administrativeCourtDecision": "Hallinto-oikeus",
    "documentType.committeeReport": "Utskottsbetänkanden",
    "documentType.courtOfAppealDecision": "Hovioikeus",
    "documentType.environmentBulletin": "EHS-tiedote",
    "documentType.euStatuteConsolidated": "EU-lagstiftning",
    "documentType.governmentBill": "Regeringspropositioner",
    "documentType.insuranceCourtDecision": "Vakuutusoikeus",
    "documentType.internationalTreatyConsolidated": "Statsfördrag",
    "documentType.labourCouncilStatement": "Työneuvoston lausunnot",
    "documentType.labourCourtDecision": "Työtuomioistuin",
    "documentType.link": "Webblänk",
    "documentType.newsArticle": "Nyheter",
    "documentType.statuteAmendment": "Författningsändring",
    "documentType.statuteConsolidated": "Lagstiftning",
    "documentType.supremeAdministrativeCourtPrecedent": "Högsta förvaltningsdomstolen",
    "documentType.supremeCourtPrecedent": "Högsta domstolen",
    "documentType.tyosuhdeasioidenKasikirja": "Työsuhdeasioiden Käsikirja",

    "documentActions.print": "Skriv ut",

    "documentActions.edilexReferenceCard": "Referenskort i Edilex",
    "documentActions.edilexStatuteAmendment": "Ändringsförfattning",
    "documentActions.finlexReferenceCard": "Referenskort i Finlex",
    "documentActions.lang-en": "På engelska",
    "documentActions.lang-fi": "På finska",
    "documentActions.lang-sv": "På svenska",
    "documentActions.pdf": "PDF",
    "documentActions.referenceCard": "Referenskort",

    "title.documentActions.document-pdf": "Öppna PDF-dokument",
    "title.documentActions.lang-en": "Avaa englanninkielinen versio",
    "title.documentActions.lang-fi": "Öppna finskspråkiga versionen",
    "title.documentActions.lang-sv": "Öppna svenskspråkiga versionen",
    "title.documentActions.reference-card": "Öppna referenskortet",
    "title.documentActions.statute-amendment": "Öppna ändringsförfattningen",

    "materialGroup.caseLaw": "Rättspraxis",
    "materialGroup.committeeReport": "Utskottsbetänkanden",
    "materialGroup.ehs": "EHS-säädösseuranta",
    "materialGroup.governmentBills": "Regeringspropositioner",
    "materialGroup.lawDrafting": "Förarbeten",
    "materialGroup.legislation": "Lagstiftning",
    "materialGroup.news": "Nyheter",
    "materialGroup.tyosuhdeasioidenKasikirja": "Työsuhdeasioiden Käsikirja",

    "description.abbreviations.statute-consolidated": "Etablerade författningsnamn och förkortningar kan användas i sökningen",
    "description.feedback": "Vi tar gärna emot respons om utvecklande och användbarhet av tjänsten. Juridisk rådgivning och tolkning av innehållet i texterna är däremot inte en del av tjänsten.",

    "keywords.actualKeyword": "Se",
    "keywords.relatedKeyword": "Se även",
  },
  en: {
    "arialabel.aside-documentKeywords": "Document's keywords",
    "arialabel.description-documentKeywords": "Select a keyword to get a list of all documents related to it",
    "arialabel.documentToc-close": "Hide sublevels",
    "arialabel.documentToc-open": "Show sublevels",
    "arialabel.materialJumpTo": "Move straight to document",
    "arialabel.nav-breadcrumb": "Breadcrumbs navigation",
    "arialabel.nav-extra": "Other pages",
    "arialabel.nav-global": "Main navigation",
    "arialabel.nav-languageSelector": "Language selector",
    "arialabel.nav-letter": "Letter navigation",
    "arialabel.nav-material": "Material navigation",
    "arialabel.nav-pageFooterLinks": "Info links",
    "arialabel.nav-toc": "Document's table of contents",
    "arialabel.searchSuggestions": "Search result suggestions",
    "arialabel.sidebarDescription": "Material description",
    "arialabel.updateInformation": "Material update information",

    "language.english": "English",
    "language.finnish": "Suomi",
    "language.swedish": "Svenska",

    // Materials (used for navigation also)
    "label.home": "label.home",
    "label.newsArticle": "label.newsArticle",
    "label.statuteConsolidated": "label.statuteConsolidated",

    // Labels
    "label.login": "Log in",
    "label.logout": "Log out",
  },
})
