import store from "@/store"
import axios from "./axios-instance"

export default {
  edilexAuthenticate: payload => axios(store)({
    url: "/edilex/login",
    method: "POST",
    data: payload,
  }),
  edilexLogout: () => axios(store)({
    url: "/edilex/logout",
    method: "POST",
  }),
  webshopAuthenticate: payload => axios(store)({
    url: "/webshop/login",
    method: "POST",
    data: payload,
  }),
  webshopLogout: () => axios(store)({
    url: "/webshop/logout",
    method: "POST",
  }),
  whoami: () => axios(store)({
    url: "/auth/whoami",
    method: "GET",
  }),
}
