<template lang="html">
  <ContentContainer
    :fullview="true"
    variant="document"
  >
    <MainContainer>
      <Throbber v-if="loading" />
      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <MainContentContainer>
        <SearchResultsNavigation
          v-if="searching"
          :next="nextSearchresult"
          :previous="previousSearchresult"
          :search-results-route="getSearchResultsRoute()"
          position="top"
        />
        <BaseArticle
          id="pdf-container"
          :language="documentLanguage"
          class="eds-BaseArticle-pdf-viewer"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script>
import { flatMap, isEqual } from "lodash"
import storeTypes from "@common/config/store-types"
import { pdf } from "@common/repository"
import materialGroups from "@/config/material-search"
import { mapActions, mapState } from "vuex"
import { ALL as materials } from "@/config/material-type"
import PDFObject from "pdfobject"

export default {
  name: "PdfViewWrapper",
  props: {
    materialType: {
      type: Array,
      required: true,
      validator(value) {
        return value.length === 1
      },
    },
    configuration: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
    /**
     * Current section configuration
     */
    section: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
  },
  data() {
    return {
      pdfData: null,
      error: null,
      loading: true,
      searchResult: {},
    }
  },
  computed: {
    ...mapState({ uiLanguage: "language" }),
    configurations() {
      return Object.keys(materials).reduce((accum, materialType) => {
        accum[materialType] = materials[materialType].configuration

        return accum
      }, {})
    },
    documentKey() {
      return this.$route.params.documentKey
    },
    documentLanguage() {
      const materialLanguages = materials[this.materialType].languages
      const documentLanguage = this.$route.params.documentLanguage
        ? this.$route.params.documentLanguage
        : this.uiLanguage

      return documentLanguage
        && materialLanguages.indexOf(documentLanguage) >= 0
        ? documentLanguage
        : materialLanguages[0] // Use first usable language as default
    },
    filename() {
      return `${this.$route.params.documentKey}.pdf`
    },
    nextSearchresult() {
      if (this.searchResult && this.searchResult.next) {
        return {
          ...this.searchResult.next,
          route: this.getDocumentRoute(this.searchResult.next),
        }
      } else {
        return null
      }
    },
    pdfUrl() {
      const blob = new Blob([this.pdfData], { type: "application/pdf" })

      // NOTE: This fixes filename in Firefox but breaks embedded viewer in Chrome
      // const file = new File([blob], this.documentKey)

      return URL.createObjectURL(blob)

      // NOTE: Directly use Api URL for the PDF. There is a problem with
      // authentication headers so this cannot be used as is. This would fix
      // filename problem.
      // let language = this.$route.params.documentLanguage
      //   ? this.$route.params.documentLanguage
      //   : this.$route.params.language
      // let documentKey = this.$route.params.documentKey
      // let materialType = this.materialType[0]
      // let documentId = `${materialType}:${language}:${documentKey}`

      // eslint-disable-next-line no-undef
      // return `${process.env.VUE_APP_API}/pdf/${documentId}`
    },
    previousSearchresult() {
      if (this.searchResult && this.searchResult.previous) {
        return {
          ...this.searchResult.previous,
          route: this.getDocumentRoute(this.searchResult.previous),
        }
      } else {
        return null
      }
    },
    searching() {
      return Object.keys(this.searchResult).length > 0
    },
  },
  watch: {
    "$route.params": function(newParams, oldParams) {
      // Slightly more complicated param check as undefined param in old will
      // not be defined at all when changing route hash. So merge them to get
      // corrected params (ie. both having same params but with changed)
      const correctedOld = Object.keys(this.$route.params).reduce(
        (accum, value) => {
          accum[value] = oldParams[value]

          return accum
        },
        {}
      )
      const correctedNew = Object.keys(this.$route.params).reduce(
        (accum, value) => {
          accum[value] = newParams[value]

          return accum
        },
        {}
      )

      // Did main document params change? -> Get new document
      if (!isEqual(correctedNew, correctedOld)) {
        this.getDocument()
        this.getPdf()
      }
    },
  },
  created() {
    this.getDocument()
    this.getPdf()
  },
  methods: {
    ...mapActions("document", { storeGetDocumentById: storeTypes.GET_DOCUMENT_BY_ID }),
    getDocument() {
      this.searchResult = {}

      const queryMaterials = this.$route.query.materialGroups
        ? this.$route.query.materialGroups.split(",")
        : materialGroups.map(group => group.id)
      const query = [
        "allWords",
        "anyWords",
        "exactPhrase",
        "notWords",
      ].filter(term => this.$route.query[term])
        .reduce((accum, term) => {
          accum[term] = this.$route.query[term]

          return accum
        }, {
          materialType: flatMap(materialGroups
            .filter(group => queryMaterials.indexOf(group.id) !== -1
            ), group => group.materialTypes),
        })
      const payload = {
        ...query,
        search: !!this.$route.query["search"],
      }
      const materialType = this.materialType[0]
      const documentId = `${materialType}:${this.documentLanguage}:${this.documentKey}`

      this.storeGetDocumentById({
        id: documentId,
        // Only send search payload when searching.
        payload: payload.search ? payload : undefined,
      })
        .then((response) => {
          // Set previous and next search results if they exist
          if (response.searchResult) {
            this.searchResult = response.searchResult
          }
        })
        .catch((error) => {
          this.searchResult = {}

          // eslint-disable-next-line no-undef
          if (this.error && process.env.VUE_APP_ENV !== "production"
            // eslint-disable-next-line no-undef
            && process.env.NODE_ENV !== "staging"
          ) {
            // eslint-disable-next-line no-console
            console.error(`Error getting document: ${error}`)
          }
        })
    },
    getDocumentRoute(document) {
      return {
        name: this.$router.findMaterialViewRoute(document.materialType),
        params: {
          language: this.uiLanguage,
          documentKey: document.documentKey,
        },
        query: this.$route.query,
      }
    },
    getPdf() {
      this.error = null
      this.loading = true
      this.searchResult = {}

      const language = this.$route.params.documentLanguage
        ? this.$route.params.documentLanguage
        : this.$route.params.language
      const documentKey = this.$route.params.documentKey
      const materialType = this.materialType[0]
      const documentId = `${materialType}:${language}:${documentKey}`

      pdf
        .getOne(documentId) // Get blob of pdf
        .then((pdfData) => {
          this.loading = false
          this.pdfData = pdfData

          const options = {
            fallbackLink: this.pdfUrl,
            pdfOpenParams: { pagemode: "thumbs" },
          }

          PDFObject.embed(this.pdfUrl, document.getElementById("pdf-container"), options)
        })
    },
    getSearchResultsRoute() {
      return {
        name: "search", // TODO: Find route when materials have their own search (if ever added)
        params: { language: this.uiLanguage },
        query: {
          ...this.$route.query,
          page: 1, // Always force to first page
        },
      }
    },
  },
}
</script>
