import { merge } from "lodash"
import defaultConfig from "@common/config/edita.config.js"
import {
  CASE_LAW,
  LAW_DRAFTING,
  LEGISLATION,
  NEWS,
  TYOSUHDEASIOIDEN_KASIKIRJA,
} from "@/config/material-group"
import { STATUTE_CONSOLIDATED } from "@/config/material-type"

const SERVICE_CODE = "hr"

const CONFIG = merge({}, defaultConfig, {
  serviceCode: SERVICE_CODE,
  auth: {
    useAuthentication: true,
    requireAuthentication: true,
  },
  routes: {
    subjects: {
      autoShowRoutes: [
        "home",
        "subjects",
      ],
      enabled: true,
      materialGroups: [
        {
          group: TYOSUHDEASIOIDEN_KASIKIRJA,
          showMore: false,
        },
        {
          group: NEWS,
          showMore: true,
        },
        {
          group: LEGISLATION,
          showMore: true,
        },
        {
          group: CASE_LAW,
          showMore: true,
        },
        {
          group: LAW_DRAFTING,
          showMore: true,
        },
      ],
    },
  },
  useSidebarDocument: true,
  // Configurations for DS components' content
  // Use Vue component name for easier distinction.
  FrontPage: {
    auth: { blocks: [] },
    guest: {
      blocks: [
        {
          text: [
            "service.description",
            "service.description2",
          ],
          type: "introduction",
          serviceCode: SERVICE_CODE,
        },
        {
          heading: "label.login",
          type: "login",
        },
      ],
    },
  },
  PageHeader: {
    requireAuthentication: false,
    extraNav: [
      {
        meta: { title: "label.keywords" },
        name: "keywords",
      },
      {
        meta: { title: "label.instructions" },
        name: "module-other-document.documentView",
        navParams: { documentKey: "ohjeet" },
      },
    ],
  },
  PageFooter: {
    requireAuthentication: false,
    PageFooterBranding: {
      url: "https://hr.fi",
      name: "hr",
      target: "_blank",
      coop: true,
    },
    PageFooterLinks: [
      {
        label: "label.feedback",
        to: {
          name: "module-other-document.documentView",
          params: { documentKey: "feedback" },
        },
      },
      {
        label: "label.contactInformation",
        to: {
          name: "module-other-document.documentView",
          params: { documentKey: "contactInformation" },
        },
      },
      {
        label: "label.privacyPolicy",
        to: {
          name: "module-other-document.documentView",
          params: { documentKey: "privacyPolicy" },
        },
      },
      {
        label: "label.termsOfUse",
        to: {
          name: "module-other-document.documentView",
          params: { documentKey: "termsOfUse" },
        },
      },
    ],
    PageFooterOtherServices: [
      {
        label: "Edilex",
        href: "https://www.edilex.fi/",
      },
      {
        label: "Edita Publishing",
        href: "https://www.edita.fi/",
      },
    ],
  },
  SearchSuggest: [
    {
      type: "document",
      listTitle: "label.statutes",
      materialType: [STATUTE_CONSOLIDATED.materialType],
      limit: 5,
      showDocumentCount: false,
    },
  ],
})

export default CONFIG
