<template lang="html">
  <ContentContainer variant="document">
    <AsideContainer v-if="document && document.toc">
      <DocumentToc
        :id="document.toc.id"
        :anchor="$route.hash"
        :children="document.toc.children"
        :label="document.toc.label"
        :options-document-toc-jump-to="configuration.sidebar.jumpToToc"
        :query-params="$route.query"
        :ui-language="uiLanguage"
        aria-label="arialabel.nav-toc"
        @jump-to-anchor="jumpToAnchor"
      />
    </AsideContainer>
    <SidebarMaterial
      v-else-if="configuration.sidebar.enabled"
      :active="$route.name"
      :advanced-search-route="advancedSearchRoute"
      :anchor="$route.hash"
      :has-search="configuration.sidebar.search"
      :has-subsection="configuration.sidebar.subsection.enabled"
      :has-update-info="configuration.sidebar.updateInfo"
      :items="section.children"
      :material-type="materialType"
      :options-material-jump-to="configuration.sidebar.jumpToDocument"
      :section="section"
      :subsubsection="configuration.sidebar.subsection.subitems"
      :ui-language="uiLanguage"
      :update-info="updateInfo"
      @material-jump-to="storeMaterialJumpTo"
      @search-global="searchGlobal"
    />
    <MainContainer>
      <Throbber v-if="loading" />
      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <MainContentContainer v-if="document">
        <SearchResultsNavigation
          v-if="searching"
          :next="nextSearchresult"
          :previous="previousSearchresult"
          :search-results-route="getSearchResultsRoute()"
          position="top"
        />
        <SearchHitsNavigation
          v-if="searchHits.length > 0"
          :first="searchHits[0].hitId"
          :highlighted="displayHighlighter"
          @hide-highlighter="hideHighlighter"
          @show-highlighter="showHighlighter"
        />
        <DocumentActions
          orientation="horizontal"
        >
          <DocumentActionsItem
            v-for="(action, index) in document.DocumentActions"
            :key="index + 1"
            :title="$te(action.label) ? $t(action.label) : action.label"
            v-bind="action"
            @click="documentAction(action)"
          >
            {{ $te(action.label) ? $t(action.label) : action.label }}
          </DocumentActionsItem>
        </DocumentActions>
        <BaseArticle
          id="document"
          :language="document.language"
        >
          <BitmapImage
            v-if="bitmapImageSrc"
            :src="bitmapImageSrc"
            class="eds-BitmapImage-document"
          />
          <DocumentCaption>
            {{ document.DocumentCaption }}
          </DocumentCaption>
          <DocumentTitle level="h1">
            {{ document.DocumentTitle }}
          </DocumentTitle>
          <p
            v-if="commonNamesAndAbbreviations"
            :aria-label="$t('arialabel.common-names-and-abbreviations', document.language)"
            class="commonnames-abbreviations"
          >
            <BaseLink
              :to="{
                name: `${document.DocumentType}.commonNamesAndAbbreviations`,
                params: {
                  language: document.language,
                  materialType: document.materialType,
                },
              }"
            >
              {{ $t("label.commonNamesAndAbbreviations", document.language) }}
            </BaseLink>: {{ commonNamesAndAbbreviations }}
          </p>
          <DocumentKeywords
            v-if="configuration.view.keywords.show && keywords.length > 0"
            :keywords="keywords"
            :ui-language="documentLanguage"
            :link-keywords="linkKeywords"
          />
          <DocumentContent
            :content="documentContent"
            :material-type="document.materialType"
          />
          <Backtotop
            v-if="showBacktotop"
            @click="goBacktotop"
          />
        </BaseArticle>
        <SidebarRelatedDocument
          v-if="relatedDocument || errorSidebar"
          @close="closeRelatedDocument"
        >
          <FeedbackMessage
            v-if="errorSidebar"
            variant="error"
          >
            {{ $t(errorSidebar) }}
          </FeedbackMessage>
          <DocumentCaption v-if="relatedDocument">
            {{ relatedDocument.DocumentCaption }}
          </DocumentCaption>
          <DocumentTitle
            v-if="relatedDocument"
            level="h1"
          >
            {{ relatedDocument.DocumentTitle }}
          </DocumentTitle>
          <DocumentContent
            v-if="relatedDocument"
            :content="relatedDocument.content"
            :material-type="relatedDocument.materialType"
          />
        </SidebarRelatedDocument>
        <SearchHitsNavigation
          v-if="searchHits.length > 0"
          :highlighted="displayHighlighter"
          :last="searchHits[searchHits.length - 1].hitId"
          position="bottom"
          @hide-highlighter="hideHighlighter"
          @show-highlighter="showHighlighter"
        />
        <SearchResultsNavigation
          v-if="searching"
          :next="nextSearchresult"
          :previous="previousSearchresult"
          :search-results-route="getSearchResultsRoute()"
          position="bottom"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script>
import {
  flatMap,
  isEqual,
  groupBy,
  uniqBy,
  merge,
} from "lodash"
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import materialGroups from "@/config/material-search"
import storeTypes from "@common/config/store-types"
import { ALL as materials } from "@/config/material-type"
import editaConfig from "@/edita.config.js"
import i18n from "@/i18n"
import Backtotop from "./mixins/Backtotop.vue"
import Sidebar from "./mixins/Sidebar.vue"

export default {
  name: "DocumentPageWrapper",
  mixins: [
    Backtotop,
    Sidebar,
  ],
  props: {
    materialType: {
      type: Array,
      required: true,
      validator(value) {
        return value.length === 1
      },
    },
    configuration: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
    /**
     * Current section configuration
     */
    section: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
  },
  data() {
    return {
      displayHighlighter: true,
      document: {
        type: Object,
        default() {
          return {}
        },
      },
      error: null,
      errorSidebar: null,
      injectedInternalLinks: false,
      keywords: [],
      loading: true,
      relatedDocument: null,
      requireLogin: false,
      searchHits: [],
      searchResult: {
        type: Object,
        default() {
          return {}
        },
      },
    }
  },
  computed: {
    ...mapState({
      backendApi: "backendApi",
      uiLanguage: "language",
    }),
    commonNamesAndAbbreviations() {
      const abbreviations = this.document
        && this.document.abbreviations instanceof Array
        ? this.document.abbreviations
        : []
      const commonNames = this.document
        && this.document.commonNames instanceof Array
        ? this.document.commonNames
        : []

      return abbreviations.length > 0 || commonNames.length > 0
        ? commonNames.concat(abbreviations).sort().join(", ")
        : null
    },
    bitmapImageSrc() {
      if (!this.document) {
        return null
      }

      const image = this.document.DocumentImage

      return image && image.id
        ? `${this.backendApi}/image/${image.id}.${image.format}`
        : null
    },
    configurations() {
      return Object.keys(materials).reduce((accum, materialType) => {
        accum[materialType] = materials[materialType].configuration

        return accum
      }, {})
    },
    documentContent() {
      if (!this.document) {
        return null
      }

      return this.document.contentHighlighted && this.displayHighlighter
        ? this.document.contentHighlighted
        : this.document.content
    },
    documentKey() {
      return this.$route.params.documentKey
    },
    documentLanguage() {
      const materialLanguages = materials[this.materialType].languages
      const documentLanguage = this.$route.params.documentLanguage
        ? this.$route.params.documentLanguage
        : this.uiLanguage

      return documentLanguage && materialLanguages.indexOf(documentLanguage) >= 0
        ? documentLanguage
        : materialLanguages[0] // Use first usable language as default
    },
    linkKeywords() {
      // Only link keywords when current document language is in UI languages
      // meaning that keywords exist for the language and we can link to them.
      return this.configuration.view.keywords.link
        && editaConfig.languages.indexOf(this.documentLanguage) >= 0
    },
    nextSearchresult() {
      if (this.searchResult && this.searchResult.next) {
        const next = merge({ query: this.$route.query }, this.getDocumentRoute(this.searchResult.next))

        return {
          ...this.searchResult.next,
          route: next,
        }
      } else {
        return null
      }
    },
    previousSearchresult() {
      if (this.searchResult && this.searchResult.previous) {
        const previous = merge({ query: this.$route.query }, this.getDocumentRoute(this.searchResult.previous))

        return {
          ...this.searchResult.previous,
          route: previous,
        }
      } else {
        return null
      }
    },
    searching() {
      return Object.keys(this.searchResult).length > 0
    },
  },
  watch: {
    "$route.params": function(newParams, oldParams) {
      // Slightly more complicated param check as undefined param in old will
      // not be defined at all when changing route hash. So merge them to get
      // corrected params (ie. both having same params but with changed)
      const correctedOld = Object.keys(this.$route.params)
        .reduce((accum, value) => {
          accum[value] = oldParams[value]

          return accum
        }, {})
      const correctedNew = Object.keys(this.$route.params)
        .reduce((accum, value) => {
          accum[value] = newParams[value]

          return accum
        }, {})

      // Did main document params change? -> Get new document
      if (!isEqual(correctedNew, correctedOld)) {
        this.closeRelatedDocument()
        this.getDocument()
      }
    },
  },
  created() {
    this.getDocument()
  },
  updated() {
    // Do linking magic for document content HTML here, after components have
    // updated and then next tick. HTML should have been updated and therefore
    // we can querySelector the elements and change them
    Vue.nextTick(() => {
      const contentElement = document.querySelector(".eds-BaseArticle .eds-DocumentContent")

      if (contentElement && !this.injectedInternalLinks) {
        const links = contentElement.querySelectorAll("a[data-ref]")

        this.makeLinks(links)
        this.makeKeywords(contentElement)
        this.makeSearchHitHighlights(contentElement)

        this.$store.dispatch(storeTypes.JUMP_TO_ANCHOR)
        this.injectedInternalLinks = true
      }
    })
  },
  methods: {
    ...mapActions("document", {
      storeGetDocumentById: storeTypes.GET_DOCUMENT_BY_ID,
      storeMaterialJumpTo: storeTypes.MATERIAL_JUMP_TO,
    }),
    closeRelatedDocument() {
      this.errorSidebar = null
      this.relatedDocument = null
    },
    documentAction({ action, type }) {
      if (type !== "button") {
        return
      }
      switch (action) {
        case "print":
          window.print()
          break
      }
    },
    getDocument() {
      this.displayHighlighter = false
      this.document = null
      this.error = null
      this.injectedInternalLinks = false
      this.loading = true
      this.searchHits = []
      this.searchResult = {}

      // Get material types from materialGroups and populate "material" to
      // reduce's initial value.
      const queryMaterials = this.$route.query.materialGroups
        ? this.$route.query.materialGroups.split(",")
        : materialGroups.map(group => group.id)
      const query = [
        "allWords",
        "anyWords",
        "exactPhrase",
        "notWords",
      ].filter(term => this.$route.query[term])
        .reduce((accum, term) => {
          accum[term] = this.$route.query[term]

          return accum
        }, {
          materialType: flatMap(materialGroups
            .filter(group => queryMaterials.indexOf(group.id) !== -1
            ), group => group.materialTypes),
        })
      const languages = this.$route.query["languages"] ? this.$route.query["languages"].split(",") : []
      const payload = {
        ...query,
        languages,
        search: !!this.$route.query["search"],
      }
      const materialType = this.materialType[0]
      const documentId = `${materialType}:${this.documentLanguage}:${this.documentKey}`

      this.storeGetDocumentById({
        id: documentId,
        // Only send search payload when searching.
        payload: payload.search ? payload : undefined,
      })
        .then((response) => {
          this.document = response
          this.loading = false

          this.$store.dispatch(storeTypes.SET_PAGE_TITLE)

          // Set previous and next search results if they exist
          if (response.searchResult) {
            this.searchResult = response.searchResult
          }
          // Show highlighted search content if it exists
          if (response.contentHighlighted) {
            this.displayHighlighter = true
          }
        })
        .catch((error) => {
          this.document = null
          this.loading = false
          this.searchResult = {}
          if (error.response) {
            if (error.response.status === 404) {
              this.$router.push({ name: "not-found" })
            }
            // TODO Duplicated in utils.js
            this.error = `error.code.${error.response.status}`
          } else {
            this.error = "error.general"
          }

          // eslint-disable-next-line no-undef
          if (this.error && process.env.VUE_APP_ENV !== "production"
            // eslint-disable-next-line no-undef
            && process.env.NODE_ENV !== "staging"
          ) {
            // eslint-disable-next-line no-console
            console.error(`Error getting document: ${error}`)
          }
        })
    },
    getDocumentRoute(document) {
      return {
        name: this.$router.findMaterialViewRoute(document.materialType),
        params: {
          documentLanguage: document.language !== this.uiLanguage
            ? document.language
            : null, // Use UI language by default
          language: this.uiLanguage,
          documentKey: document.documentKey,
        },
      }
    },
    getRelatedDocument(relatedDocumentId, hash) {
      this.errorSidebar = null
      this.storeGetDocumentById({ id: relatedDocumentId })
        .then((response) => {
          this.relatedDocument = response
          Vue.nextTick(() => {
            document.querySelector("#app").scrollTop = document.querySelector(".eds-SidebarRelatedDocument").offsetTop

            const element = hash
              ? document.querySelector(`.eds-SidebarRelatedDocument .eds-DocumentContent #${hash}`)
              : null
            const links = document.querySelectorAll(".eds-SidebarRelatedDocument .eds-DocumentContent a[data-ref]")

            this.makeLinks(links, true)

            // Fix anchor links to point into proper document view route
            // eslint-disable-next-line max-len
            const documentLinks = document.querySelectorAll(".eds-SidebarRelatedDocument .eds-DocumentContent a[href^='#']")

            documentLinks.forEach((refElement) => {
              this.insertLink(
                refElement,
                this.relatedDocument,
                refElement.text,
                refElement.hash ? refElement.hash.substring(1) : null
              )
            })

            // Scroll to related document anchor
            if (element) {
              const container = document.querySelector(".eds-SidebarRelatedDocument .eds-DocumentContent")

              container.scrollTop = element.offsetTop
            }
          })
        })
        .catch((error) => {
          if (error.response) {
            // TODO Duplicated in utils.js
            this.errorSidebar = `error.code.${error.response.status}`
          } else {
            this.errorSidebar = "error.general"
          }

          // eslint-disable-next-line no-undef
          if (this.error && process.env.VUE_APP_ENV !== "production"
            // eslint-disable-next-line no-undef
            && process.env.NODE_ENV !== "staging"
          ) {
            // eslint-disable-next-line no-console
            console.error(`Error getting document: ${error}`)
          }
        })
    },
    getSearchResultsRoute() {
      return {
        name: "search", // TODO: Find route when materials have their own search (if ever added)
        params: { language: this.uiLanguage },
        query: {
          ...this.$route.query,
          // NOTE: KORE-489: We want to go to previous list page when returning
          // from document view
          // page: 1, // Always force to first page
        },
      }
    },
    hideHighlighter() {
      this.injectedInternalLinks = false
      this.displayHighlighter = false
    },
    insertKeywords(refElement, keywords) {
      const self = this
      const container = document.createElement("div")

      refElement.parentNode.insertBefore(container, refElement.nextSibling)

      return new Vue({
        el: container,
        i18n,
        router: this.$router,
        render(h) {
          return h(Vue.component("DocumentKeywords"), {
            props: {
              uiLanguage: self.uiLanguage,
              linkKeywords: self.linkKeywords,
              keywords,
            },
          })
        },
      })
    },
    insertLink(refElement, documentData, text, hash, sidebarRelated) {
      // eslint-disable-next-line no-undef
      const ENV = process.env.VUE_APP_ENV
      const self = this
      const documentId = `${documentData.materialType}:${documentData.language}:${documentData.documentKey}`

      try {
        let route

        try {
          route = sidebarRelated
            ? null // Link to nowhere but open document to side
            : this.getDocumentRoute(documentData)
        } catch (error) {
          // Do nothing when fails to find route for document material's
          // document view.
        }

        if (route && hash) {
          route.hash = hash ? `#${hash}` : ""
        }

        if (route && documentData.language !== this.uiLanguage) {
          route.params.documentLanguage = documentData.language
        }

        // Create a new component with route props and set text to inner components
        // See more detailed documentation: https://vuejs.org/v2/guide/render-function.html
        // And easier to understand examples: https://css-tricks.com/what-does-the-h-stand-for-in-vues-render-method/
        return new Vue({
          el: refElement,
          i18n,
          router: this.$router,
          render(h) {
            return h(Vue.component("BaseLink"), {
              nativeOn: {
                click: () => {
                  // Sidebar related document should only open when content's
                  // element has appropriate attribute set.
                  if (sidebarRelated) {
                    self.getRelatedDocument(documentId, hash)
                  }
                },
              },
              props: {
                to: route,
                href: sidebarRelated ? "javascript:void(0);" : null,
                icon: sidebarRelated ? "open-drawer" : null,
              },
            }, [text])
          },
        })
      } catch (error) {
        if (ENV !== "production" && ENV !== "staging") {
          // eslint-disable-next-line no-console
          console.error("[insertLink] Error occured when injecting link element", error)
        }
      }
    },
    insertSearchHit(refElement, text, props) {
      return new Vue({
        i18n,
        el: refElement,
        render(h) {
          return h(Vue.component("SearchHitsHighlighter"), { props }, [text])
        },
      })
    },
    jumpToAnchor(payload) {
      this.$store.dispatch(storeTypes.JUMP_TO_ANCHOR, payload)
    },
    /**
     * @param array links
     * @param bool isSidebarDocument whether links are made to main or sidebar document
     */
    makeLinks(links, isSidebarDocument = false) {
      links.forEach((refElement) => {
        const materialType = refElement.getAttribute("data-type")
        const language = refElement.getAttribute("data-language")

        // Show document in sidebar when
        // - link is in main document and not in sidebar
        // - data-sidebar-document attribute is not set but application is configured to use sidebar
        // - data-sidebar-document attribute is set, and it evaluates to true
        const sidebarRelated = !isSidebarDocument
          && ((refElement.getAttribute("data-sidebar-document") === null && editaConfig.useSidebarDocument)
          || (refElement.getAttribute("data-sidebar-document") !== null && !!refElement.getAttribute("data-linked")))
        const text = refElement.innerText

        const [
          documentKey,
          ...hashParts
        ] = refElement.getAttribute("data-ref").split(":")
        const documentData = {
          language,
          documentKey,
          materialType,
        }
        const hash = hashParts.filter(part => part[1] !== "0").join("")

        this.insertLink(refElement, documentData, text, hash, sidebarRelated)
      })
    },
    makeKeywords(contentElement) {
      // eslint-disable-next-line no-undef
      const ENV = process.env.VUE_APP_ENV

      if (this.document.keywords && this.document.keywords.length > 0) {
        const sectionKeywords = []

        const documentKeywords = this.document.keywords
          // Filter whole document keywords and skip duplicates
          .filter((keywordData, index, self) => keywordData.keyword
            && !keywordData.keyword.references
            && self.findIndex(lookupData => lookupData.keyword
              && lookupData.keyword.id === keywordData.keyword.id) === index)
          .map(keywordData => keywordData.keyword)
          .sort((keywordA, keywordB) => keywordA.label.localeCompare(keywordB.label, this.uiLanguage))

        this.$set(this, "keywords", documentKeywords)

        this.document.keywords.forEach((element) => {
          if (element.references) {
            element.references.forEach((reference) => {
              if (reference
                && reference.anchor
                && reference.label
                && reference.group
              ) {
                sectionKeywords.push({
                  keyword: element.keyword,
                  group: reference.group,
                })
              }
            })
          }
        })

        if (sectionKeywords.length) {
          let headerAnchor, keywords, refElement
          const sections = groupBy(sectionKeywords, "group")

          for (const groupAnchor in sections) {
            // Get unique keywords by their id (remove duplicates)
            keywords = uniqBy(sections[groupAnchor], anchor => anchor.keyword.id)
              .map(anchor => anchor.keyword)
              .sort(keyword => keyword.label)
            headerAnchor = contentElement.querySelectorAll(`.anchors #${groupAnchor}`)[0]

            if (headerAnchor) {
              refElement = headerAnchor.parentNode.nextElementSibling

              this.insertKeywords(refElement, keywords)
            } else if (ENV !== "production" && ENV !== "staging") {
              // eslint-disable-next-line no-console
              console.error(`Anchor '${groupAnchor}' not found in `
                + "makeKeywords(). Keywords for anchor:", keywords)
            }
          }
        }
      }
    },
    makeSearchHitHighlights(contentElement) {
      if (!this.$route.query.search) {
        return
      }
      const hits = contentElement.querySelectorAll("span.search-hit-highlight")

      if (hits) {
        hits.forEach((refElement, idx) => {
          const hitId = refElement.getAttribute("id")
          const previous = idx > 0 ? hits[idx - 1].getAttribute("id") : null
          const next = hits.length > idx + 1 ? hits[idx + 1].getAttribute("id") : null
          const text = refElement.innerText

          this.insertSearchHit(refElement, text, {
            hitId,
            next,
            previous,
          })

          this.searchHits.push({
            hitId,
            text,
            next,
            previous,
          })
        })
      }
    },
    showHighlighter() {
      this.injectedInternalLinks = false
      this.displayHighlighter = true
    },
  },
}
</script>
<style lang="scss">
.eds-DocumentActionsItem-print {
  margin-left: auto;
}
</style>
<style lang="scss" scoped>
.commonnames-abbreviations {
  margin-top: 0;
  font-size: .875rem;
  color: #656c74;
  a {
    color: #003f75;
  }
}
</style>
