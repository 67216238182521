<template lang="html">
  <ContentContainer variant="section">
    <SidebarMaterial
      :active="$route.name"
      :anchor="$route.hash"
      :advanced-search-route="advancedSearchRoute"
      :has-subsection="configuration.sidebar.subsection.enabled"
      :has-search="configuration.sidebar.search"
      :subsubsection="configuration.sidebar.subsection.subitems"
      :has-update-info="configuration.sidebar.updateInfo"
      :items="section.children"
      :material-type="materialType"
      :options-material-jump-to="configuration.sidebar.jumpToDocument"
      :section="section"
      :ui-language="uiLanguage"
      :update-info="updateInfo"
      @material-jump-to="storeMaterialJumpTo"
      @search-global="searchGlobal"
    />
    <MainContainer>
      <Heading
        v-if="heading"
        level="h1"
      >
        {{ $t(heading) }}
      </Heading>

      <Throbber v-if="loading" />
      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <MainContentContainer v-if="!loading && !error">
        <BaseParagraph v-if="documents.length === 0">
          {{ $t("error.noDocuments") }}
        </BaseParagraph>

        <DocumentList
          v-if="documents.length > 1"
          :configurations="configurations"
          :documents="documents"
          :image-src="imageSrc"
          :options-document-list-item="optionsDocumentListItem"
          :ui-language="uiLanguage"
          variant="latest"
          view="list"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script lang="js">
import { isEqual } from "lodash"
import { mapActions } from "vuex"
import storeTypes from "@common/config/store-types"
import { ALL as materials } from "@/config/material-type"
import { handleError } from "./utils.js"
import DocumentBase from "./mixins/DocumentBase.vue"
import Sidebar from "./mixins/Sidebar.vue"

export default {
  name: "DocumentJumpToWrapper",
  mixins: [
    DocumentBase,
    Sidebar,
  ],
  props: {
    /**
     * Material type used for listing
     */
    materialType: {
      type: Array,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
    /**
     * Current section configuration
     */
    section: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
  },
  data() {
    return {
      loading: true,
      error: null,
      requireLogin: false,
      documents: [],
      optionsDocumentListItem: { DocumentActions: { orientation: "vertical" } },
    }
  },
  computed: {
    configurations() {
      return this.documents
        .map(document => document.materialType)
        .filter((materialType, index, self) => self.indexOf(materialType) === index)
        .reduce((accum, materialType) => {
          accum[materialType] = materials[materialType].configuration

          return accum
        }, {})
    },
  },
  watch: {
    "$route.query": function(newParams, oldParams) {
      if (!isEqual(newParams, oldParams)) {
        this.getDocuments()
      }
    },
  },
  created() {
    this.getDocuments()
  },
  methods: {
    ...mapActions("document", {
      storeMaterialJumpTo: storeTypes.MATERIAL_JUMP_TO,
      storeSearchDocuments: storeTypes.SEARCH_DOCUMENTS,
    }),
    getDocuments() {
      // TODO: Is input validation required?
      const CHAPTER = this.$route.query.chapter
      const NUMBER = this.$route.query.number
      const SECTION = this.$route.query.section
      const YEAR = Math.max(0, parseInt(this.$route.query.year))
      const ANCHOR = (CHAPTER ? `L${CHAPTER}` : "") + (SECTION ? `P${SECTION}` : "")

      const payload = {
        languages: [this.uiLanguage],
        limit: this.configuration.listing.documentLimit,
        materialType: this.materialType,
        number: NUMBER,
        year: YEAR,
      }

      this.loading = true
      this.error = null
      this.content = null
      this.documents = []

      this.storeSearchDocuments(payload)
        .then((result) => {
          // if (result.documentCount === 0) {
          //   // TODO: fetch smur? Headpower currently does not have smur documents
          //   // these are linked to Edilex directly
          //   payload.materialType = STATUTE_METADATA.materialType
          //   return api.getDocuments(payload).then(response => response.documents)
          // } elseif (result.documentCount === 1) {
          if (result.documentCount === 1) {
            const document = result.documents[0]

            // NOTE: Use replace here instead of push so redirect page (this component)
            // is not left in browser history. Replace the route with document view
            // so when user uses back button of browser, it redirects to page where
            // jump to was used in.
            this.$router.replace({
              name: document.routes.view,
              params: {
                documentKey: document.documentKey,
                documentLanguage: document.language !== this.uiLanguage ? document.language : undefined,
                language: this.uiLanguage,
              },
              hash: ANCHOR.length > 0 ? `#${ANCHOR}` : "",
            })
          } else {
            this.documents = result.documents
            this.loading = false
          }
        })
        .catch((error) => {
          // TODO This hasn't been checked that it works since
          // we don't have any test material to access this wrapper.
          this.loading = false
          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
  },
}
</script>
