import { merge } from "lodash"
import { STATUTE_AMENDMENT } from "@/config/material-type"
import { makeDefaultMaterialRoute } from "@common/router/utils"

// Wrapper components for DS components
import CommonNamesAndAbbreviationsWrapper from "@wrappers/CommonNamesAndAbbreviationsWrapper.vue"
import DirectoryListWrapper from "@wrappers/DirectoryListWrapper.vue"
import DocumentJumpToWrapper from "@wrappers/DocumentJumpToWrapper.vue"
import DocumentListWrapper from "@wrappers/DocumentListWrapper.vue"
import DocumentPageWrapper from "@wrappers/DocumentPageWrapper.vue"

export default function(material, {
  commonNamesAndAbbreviations = false,
  highlight = false,
  isPublic = false,
} = {}) {
  if (!STATUTE_AMENDMENT) {
    throw "STATUTE_AMENDMENT configuration required in modules material-type.js"
  }

  return merge({}, makeDefaultMaterialRoute(material), {
    path: "lainsaadanto",
    children: [
      {
        path: "",
        name: "statuteConsolidated.index",
        component: DirectoryListWrapper,
        meta: {
          title: "heading.statuteConsolidated.index",
          isPublic: !!isPublic,
          navOrder: 2,
        },
      },
      {
        path: "uusimmat",
        name: "statuteConsolidated.latest",
        component: DocumentListWrapper,
        meta: {
          title: "heading.statuteAmendment.latest",
          isPublic: !!isPublic,
          navOrder: 1,
        },
        props: {
          // Override legislation material type and show the latest listing by
          materialType: [STATUTE_AMENDMENT.materialType],
        },
      },
      commonNamesAndAbbreviations ? {
        path: "lempinimet",
        name: "statuteConsolidated.commonNamesAndAbbreviations",
        component: CommonNamesAndAbbreviationsWrapper,
        meta: {
          title: "heading.statuteConsolidated.commonNamesAndAbbreviations",
          isPublic: !!isPublic,
          navOrder: 1,
        },
      } : undefined,
      {
        path: ":documentKey(\\d+)/:documentLanguage(en|fi|sv)?",
        name: "statuteConsolidated.documentView",
        component: DocumentPageWrapper,
        meta: {
          validateDocumentLanguage: true,
          isPublic: !!isPublic,
          navOrder: 3,
        },
        hidden: true, // Do not show in Nav
      },
      {
        path: "siirry",
        name: "statuteConsolidated.jumpto",
        component: DocumentJumpToWrapper,
        meta: {
          title: "heading.statuteConsolidated.jumpto",
          isPublic: !!isPublic,
          navOrder: 3,
        },
        hidden: true,
      },
      {
        // TODO: PDF should not use normal routing ? Need to think how PDFs work
        // Where are they downloaded from?
        path: ":documentKey(\\d+)-:documentLanguage(en|fi|sv)?.pdf",
        name: "statuteConsolidated.documentPdf",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        meta: {
          validateDocumentLanguage: true,
          isPublic: !!isPublic,
          navOrder: 3,
        },
        hidden: true, // Do not show in Nav
      },
    ].filter(c => c), // Filter out non-route children
    highlightItem: highlight,
  })
}
