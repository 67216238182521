// Use commonly defined routes, and customize them here if needed.
// Import section functions
import { merge } from "lodash"
import {
  ADMINISTRATIVE_COURT_DECISION as ADMINISTRATIVE_COURT_DECISION_ROUTE,
  CASE_LAW as CASE_LAW_ROUTE,
  COURT_OF_APPEAL_DECISION as COURT_OF_APPEAL_DECISION_ROUTE,
  COMMITTEE_REPORT as COMMITTEE_REPORT_ROUTE,
  GOVERNMENT_BILL as GOVERNMENT_BILL_ROUTE,
  LABOUR_COUNCIL_STATEMENT as LABOUR_COUNCIL_STATEMENT_ROUTE,
  LABOUR_COURT_DECISION as LABOUR_COURT_DECISION_ROUTE,
  LAW_DRAFTING as LAW_DRAFTING_ROUTE,
  INSURANCE_COURT_DECISION as INSURANCE_COURT_DECISION_ROUTE,
  NEWS_ARTICLE as NEWS_ARTICLE_ROUTE,
  STATUTE_CONSOLIDATED as STATUTE_CONSOLIDATED_ROUTE,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT as SACP_ROUTE,
  SUPREME_COURT_PRECEDENT as SUPREME_COURT_PRECEDENT_ROUTE,
  TYOSUHDEASIOIDEN_KASIKIRJA as TSAK_ROUTE,
} from "@common/router/routes"
import { makeGroupRoute } from "@common/router/utils"

// Use customized material configuration for routes
// Do not change material configuration here
import { CASE_LAW, LAW_DRAFTING } from "@/config/material-group"
import {
  ADMINISTRATIVE_COURT_DECISION,
  COMMITTEE_REPORT,
  COURT_OF_APPEAL_DECISION,
  DEFAULT_CONFIGURATION as DEFAULT_MATERIAL_CONFIGURATION,
  GOVERNMENT_BILL,
  INSURANCE_COURT_DECISION,
  LABOUR_COUNCIL_STATEMENT,
  LABOUR_COURT_DECISION,
  NEWS_ARTICLE,
  STATUTE_CONSOLIDATED,
  SUPREME_COURT_PRECEDENT,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
  TYOSUHDEASIOIDEN_KASIKIRJA,
} from "@/config/material-type"

// Create material routes using customized configuration
const administrativeCourtDecision = ADMINISTRATIVE_COURT_DECISION_ROUTE(ADMINISTRATIVE_COURT_DECISION)
const committeeReport = COMMITTEE_REPORT_ROUTE(COMMITTEE_REPORT)
const courtOfAppealDecision = COURT_OF_APPEAL_DECISION_ROUTE(COURT_OF_APPEAL_DECISION)
const governmentBill = GOVERNMENT_BILL_ROUTE(GOVERNMENT_BILL)
const insuranceCourtDecision = INSURANCE_COURT_DECISION_ROUTE(INSURANCE_COURT_DECISION)
const labourCouncilStatement = LABOUR_COUNCIL_STATEMENT_ROUTE(LABOUR_COUNCIL_STATEMENT)
const labourCourtDecision = LABOUR_COURT_DECISION_ROUTE(LABOUR_COURT_DECISION)
const newsArticle = NEWS_ARTICLE_ROUTE(NEWS_ARTICLE)
const statuteConsolidated = STATUTE_CONSOLIDATED_ROUTE(STATUTE_CONSOLIDATED)
const supremeCourtPrecedent = SUPREME_COURT_PRECEDENT_ROUTE(SUPREME_COURT_PRECEDENT)
const supremeAdministrativeCourtPrecedent = SACP_ROUTE(SUPREME_ADMINISTRATIVE_COURT_PRECEDENT)
const tyosuhdeasioidenKasikirja = TSAK_ROUTE(TYOSUHDEASIOIDEN_KASIKIRJA, { highlight: true })

// Create group routes and their main route ("index")
const caseLaw = CASE_LAW_ROUTE({
  materialType: CASE_LAW.materialTypes,
  configuration: merge({}, DEFAULT_MATERIAL_CONFIGURATION, {
    listing: {
      caption: true,
      title: true,
      type: true,
      references: true,
    },
    sidebar: { search: true },
  }),
  title: CASE_LAW.label,
  description: "description.caseLaw",
}, { path: "" }) // Remove path from index as group has the path
const caseLawGroup = makeGroupRoute([
  caseLaw, // First item determines navigation item (label)
  supremeCourtPrecedent,
  supremeAdministrativeCourtPrecedent,
  courtOfAppealDecision,
  labourCourtDecision,
  insuranceCourtDecision,
  labourCouncilStatement,
  administrativeCourtDecision,
], {
  path: "oikeuskaytanto", // Prefix all routes with this route
  keepOnlyDocumentView: true, // Filter default routes the module does not use
})
const lawDrafting = LAW_DRAFTING_ROUTE({
  materialType: LAW_DRAFTING.materialTypes,
  configuration: merge({}, DEFAULT_MATERIAL_CONFIGURATION, {
    listing: {
      caption: true,
      title: true,
      type: true,
      references: true,
    },
    sidebar: { search: true },
  }),
  title: LAW_DRAFTING.label,
  description: "description.lawDrafting",
}, { path: "" }) // Remove path from index as group has the path
const lawDraftingGroup = makeGroupRoute([
  lawDrafting, // First item determines navigation item (label)
  committeeReport,
  governmentBill,
], {
  path: "esityot", // Prefix all routes with this route
  keepOnlyDocumentView: true, // Filter default routes the module does not use
})

// Ordering here determines navigation ordering. Do NOT sort them alphabetically
// like everywhere else!
export default [
  tyosuhdeasioidenKasikirja,
  newsArticle,
  statuteConsolidated,
  caseLawGroup,
  lawDraftingGroup,
  // TODO: New material when added: "Työehtosopimukset"
]
