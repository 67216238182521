import store from "@/store"
import axios from "./axios-instance"

export default {
  getOne: pdfId => axios(store)({
    url: `/pdf/${pdfId}`,
    method: "GET",
    responseType: "arraybuffer",
  })
    .then(response => response.data
      //Create a Blob from the PDF Stream
      // new Blob([response.data], { type: "application/pdf;base64" })
    ),
}
