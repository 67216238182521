<script>
import { merge } from "lodash"
import { mapActions, mapState } from "vuex"
import storeTypes from "@common/config/store-types"
import { handleError } from "@common/ds-component-wrappers/utils"
import editaConfig from "@/edita.config.js"

export default {
  data() {
    return { suggestionItems: [] }
  },
  computed: {
    ...mapState({ uiLanguage: "language" }),
    advancedSearchRoute() {
      return {
        name: "search",
        params: { language: this.uiLanguage },
      }
    },
    suggestionGroupsOptions() {
      return editaConfig.SearchSuggest
    },
  },
  methods: {
    ...mapActions("document", { storeSearchDocuments: storeTypes.SEARCH_DOCUMENTS }),
    ...mapActions("keyword", { storeSearchKeywords: storeTypes.SEARCH_KEYWORDS }),
    clearSuggest() {
      this.$set(this, "suggestionItems", [])
    },
    async getSuggest(allWords) {
      await Promise.all(
        this.suggestionGroupsOptions.map((suggest) => {
          switch (suggest.type) {
            case "document": {
              const payload = {
                allWords: allWords,
                // languages: editaConfig.languages,
                limit: suggest.limit,
                materialType: suggest.materialType,
                offset: 0,
              }

              return this.storeSearchDocuments(payload)
            }
            case "keyword": {
              // Make payload for search
              const payload = {
                language: this.uiLanguage,
                text: allWords,
              }

              return this.storeSearchKeywords(payload)
            }
            default:
              return null
          }
        }))
        .then((response) => {
          const suggestionItems = []

          response.forEach((suggestions, index) => {
            if (suggestions.items) {
              for (const keyword of suggestions.items) {
                suggestionItems.push({
                  label: keyword.label,
                  documentCount: keyword.documentCount,
                  route: {
                    name: "keywords",
                    params: {
                      text: keyword.label,
                      language: this.uiLanguage,
                    },
                  },
                  showCount: this.suggestionGroupsOptions[index].showDocumentCount,
                  title: this.$t(this.suggestionGroupsOptions[index].listTitle),
                })
              }
            } else if (suggestions.documents) {
              for (const document of suggestions.documents) {
                suggestionItems.push({
                  label: document.DocumentTitle,
                  route: {
                    name: this.$router.findMaterialViewRoute(document.materialType),
                    params: {
                      documentKey: document.documentKey,
                      documentLanguage: document.language !== this.uiLanguage ? document.language : undefined,
                      language: this.uiLanguage,
                    },
                  },
                  showCount: this.suggestionGroupsOptions[index].showDocumentCount,
                  title: this.$t(this.suggestionGroupsOptions[index].listTitle),
                })
              }
            }
          })
          this.$set(this, "suggestionItems", suggestionItems)
        })
        .catch((error) => {
          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
    searchGlobal({ searchTerm, materialType }) {
      if (searchTerm.length > 0) {
      // Perform global search query
        this.$router.push(merge({}, this.advancedSearchRoute, {
          query: {
            allWords: searchTerm,
            materialType: materialType,
            search: true,
          },
        }))
      }
    },
  },
}
</script>
