import { merge } from "lodash"
import { makeDefaultMaterialRoute } from "@common/router/utils"

import DocumentListWrapper from "@wrappers/DocumentListWrapper.vue"
import DocumentPageWrapper from "@wrappers/DocumentPageWrapper.vue"

export default function(material, { highlight = false, isPublic = false } = {}) {
  return merge({}, makeDefaultMaterialRoute(material), {
    path: "hao",
    children: [
      {
        path: "",
        name: "administrativeCourtDecision.index",
        component: DocumentListWrapper,
        meta: {
          title: "heading.administrativeCourtDecision.index",
          isPublic: !!isPublic,
        },
      },
      {
        path: ":documentKey([\\w_]+)",
        name: "administrativeCourtDecision.documentView",
        component: DocumentPageWrapper,
        meta: {
          validateDocumentLanguage: true,
          isPublic: !!isPublic,
        },
        hidden: true, // Do not show in Nav
      },
    ],
    highlightItem: highlight,
  })
}
