<template lang="html">
  <ContentContainer variant="subject">
    <MainContainer>
      <Heading level="h1">
        {{ subjectName ? subjectName : $t("label.subjects") }}
      </Heading>

      <Throbber v-if="loading" />
      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <MainContentContainer v-if="subjectId">
        <SubjectList
          v-for="(materialGroup, index) in materialGroups"
          :key="index + 1"
          :label="materialGroup.group.label"
          :material-group="materialGroup.group.id"
          :material-types="materialGroup.group.materialTypes"
          :show-more="materialGroup.showMore"
          :subject="subjectId"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script>
import { mapState } from "vuex"
import storeTypes from "@common/config/store-types"
import editaConfig from "@/edita.config.js"

import SubjectList from "./helpers/SubjectList.vue"

export default {
  name: "SubjectListWrapper",
  components: { SubjectList },
  data() {
    return {
      error: null,
      loading: true,
      subjectId: null,
      subjectName: null,
    }
  },
  computed: {
    ...mapState({ uiLanguage: "language" }),
    ...mapState("static", ["subjects"]),
    materialGroups() {
      return editaConfig.routes.subjects.materialGroups
    },
  },
  watch: {
    "$route.params.text": "getSubject", // Get subjects if route is changed
    "subjects": "getSubject", // Get subjects once static content is loaded
  },
  created() {
    this.getSubject()
  },
  methods: {
    getSubject() {
      const text = this.$route.params.text

      if (typeof this.subjects === "object" && this.subjects[this.uiLanguage]) {
        const subject = this.subjects[this.uiLanguage].find(item => item.label === text)

        if (subject) {
          this.loading = false
          this.subjectId = subject.id
          this.subjectName = subject.label
          this.$store.dispatch(storeTypes.SET_PAGE_TITLE)
        }
      }
    },
  },
}
</script>
