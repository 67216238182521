import { merge } from "lodash"
import * as defaultMaterial from "@common/config/material-type"

const DEFAULT_CONFIGURATION = defaultMaterial.DEFAULT_CONFIGURATION

// Service specific modifications can be done here
const STATUTE_AMENDMENT_CUSTOM = {
  configuration: {
    sidebar: {
      // Override the detaul material type (statute-amendment) with something else
      updateInfo: defaultMaterial.STATUTE_CONSOLIDATED.materialType,
    },
  },
}
const STATUTE_CONSOLIDATED_CUSTOM = {
  configuration: {
    actions: {
      edilexReferenceCard: false,
      languages: false,
    },
  },
}

// Combine custom configuration to default
const ADMINISTRATIVE_COURT_DECISION = defaultMaterial.ADMINISTRATIVE_COURT_DECISION
const COMMITTEE_REPORT = defaultMaterial.COMMITTEE_REPORT
const COURT_OF_APPEAL_DECISION = defaultMaterial.COURT_OF_APPEAL_DECISION
const GOVERNMENT_BILL = defaultMaterial.GOVERNMENT_BILL
const INSURANCE_COURT_DECISION = defaultMaterial.INSURANCE_COURT_DECISION
const LABOUR_COUNCIL_STATEMENT = defaultMaterial.LABOUR_COUNCIL_STATEMENT
const LABOUR_COURT_DECISION = defaultMaterial.LABOUR_COURT_DECISION
const MODULE_OTHER_DOCUMENT = defaultMaterial.MODULE_OTHER_DOCUMENT
const NEWS_ARTICLE = defaultMaterial.NEWS_ARTICLE
const STATUTE_AMENDMENT = merge(
  {},
  defaultMaterial.STATUTE_AMENDMENT,
  STATUTE_AMENDMENT_CUSTOM
)
const STATUTE_CONSOLIDATED = merge(
  {},
  defaultMaterial.STATUTE_CONSOLIDATED,
  STATUTE_CONSOLIDATED_CUSTOM
)
const SUPREME_ADMINISTRATIVE_COURT_PRECEDENT = defaultMaterial.SUPREME_ADMINISTRATIVE_COURT_PRECEDENT
const SUPREME_COURT_PRECEDENT = defaultMaterial.SUPREME_COURT_PRECEDENT
const TYOSUHDEASIOIDEN_KASIKIRJA = defaultMaterial.TYOSUHDEASIOIDEN_KASIKIRJA

const ALL = {
  [ADMINISTRATIVE_COURT_DECISION.materialType]: ADMINISTRATIVE_COURT_DECISION,
  [COMMITTEE_REPORT.materialType]: COMMITTEE_REPORT,
  [COURT_OF_APPEAL_DECISION.materialType]: COURT_OF_APPEAL_DECISION,
  [GOVERNMENT_BILL.materialType]: GOVERNMENT_BILL,
  [INSURANCE_COURT_DECISION.materialType]: INSURANCE_COURT_DECISION,
  [LABOUR_COUNCIL_STATEMENT.materialType]: LABOUR_COUNCIL_STATEMENT,
  [LABOUR_COURT_DECISION.materialType]: LABOUR_COURT_DECISION,
  [MODULE_OTHER_DOCUMENT.materialType]: MODULE_OTHER_DOCUMENT,
  [NEWS_ARTICLE.materialType]: NEWS_ARTICLE,
  [STATUTE_AMENDMENT.materialType]: STATUTE_AMENDMENT,
  [STATUTE_CONSOLIDATED.materialType]: STATUTE_CONSOLIDATED,
  [SUPREME_ADMINISTRATIVE_COURT_PRECEDENT.materialType]: SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
  [SUPREME_COURT_PRECEDENT.materialType]: SUPREME_COURT_PRECEDENT,
  [TYOSUHDEASIOIDEN_KASIKIRJA.materialType]: TYOSUHDEASIOIDEN_KASIKIRJA,
}

export {
  ADMINISTRATIVE_COURT_DECISION,
  ALL,
  COMMITTEE_REPORT,
  COURT_OF_APPEAL_DECISION,
  DEFAULT_CONFIGURATION,
  GOVERNMENT_BILL,
  INSURANCE_COURT_DECISION,
  LABOUR_COUNCIL_STATEMENT,
  LABOUR_COURT_DECISION,
  MODULE_OTHER_DOCUMENT,
  NEWS_ARTICLE,
  STATUTE_AMENDMENT,
  STATUTE_CONSOLIDATED,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
  SUPREME_COURT_PRECEDENT,
  TYOSUHDEASIOIDEN_KASIKIRJA,
}
