<template lang="html">
  <ContentContainer variant="section">
    <SidebarMaterial
      :active="$route.name"
      :anchor="$route.hash"
      :advanced-search-route="advancedSearchRoute"
      :has-subsection="configuration.sidebar.subsection.enabled"
      :has-search="configuration.sidebar.search"
      :subsubsection="configuration.sidebar.subsection.subitems"
      :has-update-info="configuration.sidebar.updateInfo"
      :items="section.children"
      :material-type="materialType"
      :options-material-jump-to="configuration.sidebar.jumpToDocument"
      :section="section"
      :ui-language="uiLanguage"
      :update-info="updateInfo"
      @material-jump-to="storeMaterialJumpTo"
      @search-global="searchGlobal"
    />
    <MainContainer>
      <Heading
        v-if="heading"
        level="h1"
      >
        {{ $t(heading) }}
      </Heading>

      <BaseForm>
        <FieldsetCheckboxGroup
          v-if="materialFilters.length > 1"
          v-model="internalMaterialFilter"
          :disabled="loading"
          legend="label.filter"
          orientation="horizontal"
        />
      </BaseForm>

      <Throbber v-if="loading" />
      <FeedbackMessage
        v-if="error && hasMaterialsUiLanguage"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>
      <FeedbackMessage
        v-if="!hasMaterialsUiLanguage"
        variant="info"
      >
        {{ $t("label.material.onlyDefaultLanguage") }}
        <BaseLink :to="defaultLanguageRoute">
          {{ $t("label.moveToDefaultLanguage") }}
        </BaseLink>
      </FeedbackMessage>
      <MainContentContainer>
        <DocumentList
          v-if="documents.length > 0"
          :configurations="configurations"
          :documents="documents"
          :image-src="imageSrc"
          :options-document-list-item="optionsDocumentListItem"
          :ui-language="uiLanguage"
          variant="latest"
          view="list"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script lang="js">
import { isEqual, flatMap } from "lodash"
import { mapActions, mapState } from "vuex"
import storeTypes from "@common/config/store-types"
import { ALL as materials } from "@/config/material-type"
import editaConfig from "@/edita.config.js"
import { handleError } from "./utils.js"
import DocumentBase from "./mixins/DocumentBase.vue"
import MaterialFilter from "./mixins/MaterialFilter.vue"
import Sidebar from "./mixins/Sidebar.vue"

export default {
  name: "DocumentListWrapper",
  mixins: [
    DocumentBase,
    MaterialFilter,
    Sidebar,
  ],
  props: {
    /**
     * Material type used for listing
     */
    materialType: {
      type: Array,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
    /**
     * Current section configuration
     */
    section: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
  },
  data() {
    return {
      loading: true,
      error: null,
      requireLogin: false,
      documents: [],
      optionsDocumentListItem: editaConfig.DocumentListItem,
    }
  },
  computed: {
    ...mapState({
      backendApi: "backendApi",
      uiLanguage: "language",
    }),
    configurations() {
      return Object.keys(materials).reduce((accum, materialType) => {
        accum[materialType] = materials[materialType].configuration

        return accum
      }, {})
    },
  },
  watch: {
    // call again the method if the route changes
    "$route.params": function(newParams, oldParams) {
      if (!isEqual(newParams, oldParams)) {
        this.getLatest()
      }
    },
  },
  created() {
    this.getLatest()
  },
  methods: {
    ...mapActions("document", {
      storeMaterialJumpTo: storeTypes.MATERIAL_JUMP_TO,
      storeSearchDocuments: storeTypes.SEARCH_DOCUMENTS,
    }),
    getLatest() {
      this.loading = true
      this.error = null
      this.content = null
      this.documents = [] // Reset document list, otherwise you will see

      // previous material's documents briefly when switching to other material
      let currentMaterialTypes = flatMap(this.materialFilters
        .filter(group => group.selected), group => group.materialTypes
      ).filter(material => material)

      if (currentMaterialTypes.length === 0) {
        currentMaterialTypes = this.materialType
      }
      const payload = {
        materialType: currentMaterialTypes,
        languages: [this.uiLanguage],
        limit: this.configuration.listing.documentLimit,
        sort: "latestFirst",
      }

      // Search is only way to get multiple materials in same
      this.storeSearchDocuments(payload)
        .then((response) => {
          this.documents = response.documents
          this.loading = false
          if (response.documents.length === 0) {
            this.error = "error.noDocuments"
          }
        })
        .catch((error) => {
          this.loading = false
          if (error.response && error.response.status === 401) {
            this.requireLogin = true
          }
          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
    updateMaterialFilter() {
      this.getLatest()
    },
  },
}
</script>
