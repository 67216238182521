import store from "@/store"
import axios from "./axios-instance"

export default {
  getAll: () => axios(store)({
    url: "/updated-info",
    method: "GET",
  })
    .then(response => response.data),
  getSingle: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || typeof payload.materialType !== "string"
      || typeof payload.language !== "string"
    ) {
      reject(new Error("[updatedInfo.getSingle] Payload incorrect"))
    }
    axios(store)({
      url: `/updated-info/${payload.materialType}/${payload.language}`,
      method: "GET",
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
}
