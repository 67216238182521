import store from "@/store"
import axios from "./axios-instance"

export default {
  getSubject: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || typeof payload.language !== "string"
      || typeof payload.subject !== "string"
    ) {
      reject(new Error("[subject.getSubject] Payload incorrect"))
    }

    axios(store)({
      url: `/subject/${payload.language}/${payload.subject}`,
      method: "GET",
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
  getSubjects: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || typeof payload.language !== "string"
    ) {
      reject(new Error("[subject.getSubjects] Payload incorrect"))
    }

    axios(store)({
      url: `/subject/${payload.language}`,
      method: "GET",
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
}
