import { merge } from "lodash"
import { makeDefaultMaterialRoute } from "@common/router/utils"

import ArchiveWrapper from "@wrappers/ArchiveWrapper.vue"
import DocumentListWrapper from "@wrappers/DocumentListWrapper.vue"

export default function(material, { highlight = false, isPublic = false, path = null } = {}) {
  return merge({}, makeDefaultMaterialRoute(material), {
    path: path !== null ? path : "esityot",
    children: [
      {
        path: "",
        name: "lawDrafting.index",
        component: DocumentListWrapper,
        meta: {
          title: "heading.lawDrafting.index",
          isPublic: !!isPublic,
        },
      },
      {
        path: "vuosihakemisto/:year(\\d{4})?",
        name: "lawDrafting.archive",
        component: ArchiveWrapper,
        meta: { title: "heading.lawDrafting.archive" },
      },
    ],
    highlightItem: highlight,
  })
}
