// This file is dedicated for grouped materials; it is used for routing and search
import {
  ADMINISTRATIVE_COURT_DECISION,
  COMMITTEE_REPORT,
  COURT_OF_APPEAL_DECISION,
  GOVERNMENT_BILL,
  INSURANCE_COURT_DECISION,
  LABOUR_COUNCIL_STATEMENT,
  LABOUR_COURT_DECISION,
  NEWS_ARTICLE,
  STATUTE_CONSOLIDATED,
  SUPREME_COURT_PRECEDENT,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
  TYOSUHDEASIOIDEN_KASIKIRJA as TYOSUHDEASIOIDEN_KASIKIRJA_MATERIAL,
} from "./material-type"

const CASE_LAW = {
  id: "caseLaw",
  label: "materialGroup.caseLaw",
  materialTypes: [
    SUPREME_COURT_PRECEDENT.materialType,
    SUPREME_ADMINISTRATIVE_COURT_PRECEDENT.materialType,
    COURT_OF_APPEAL_DECISION.materialType,
    LABOUR_COURT_DECISION.materialType,
    ADMINISTRATIVE_COURT_DECISION.materialType,
    INSURANCE_COURT_DECISION.materialType,
    LABOUR_COUNCIL_STATEMENT.materialType,
  ],
  selected: true,
}

const LAW_DRAFTING = {
  id: "lawDrafting",
  label: "materialGroup.lawDrafting",
  materialTypes: [
    COMMITTEE_REPORT.materialType,
    GOVERNMENT_BILL.materialType,
  ],
  selected: true,
}

const LEGISLATION = {
  id: "legislation",
  label: "materialGroup.legislation",
  materialTypes: [STATUTE_CONSOLIDATED.materialType],
  selected: true,
}

const NEWS = {
  id: "news",
  label: "materialGroup.news",
  materialTypes: [NEWS_ARTICLE.materialType],
  selected: true,
}

const TYOSUHDEASIOIDEN_KASIKIRJA = {
  id: "tyosuhdeasioidenKasikirja",
  label: "materialGroup.tyosuhdeasioidenKasikirja",
  materialTypes: [TYOSUHDEASIOIDEN_KASIKIRJA_MATERIAL.materialType],
  selected: true,
}

export {
  CASE_LAW,
  LAW_DRAFTING,
  LEGISLATION,
  NEWS,
  TYOSUHDEASIOIDEN_KASIKIRJA,
}
