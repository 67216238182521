import {
  flatMap,
  truncate,
  uniq,
} from "lodash"
import i18n from "@/i18n" // This is magically included from module in common code. Is there better option?
import editaConfig from "@/edita.config.js" // This is magically included from module in common code.
import GenericWrapper from "@wrappers/GenericWrapper.vue"
import MaterialPageWrapper from "@wrappers/MaterialPageWrapper.vue"

/**
 * Add material specific routes to document object and its' reference documents
 * @param {*} router VueRouter
 * @param {Object} document Document object
 */
function addRouteNames(router, document) {
  document.routes = router.getRouteNames(document.materialType)
  if (document.references) {
    document.references = document.references.map((reference) => {
      if (reference && reference.type === "document" && reference.document) {
        reference.document.routes = router.getRouteNames(reference.document.materialType)
      }

      return reference
    })
  }

  return document
}

/**
 * Find index route configuration (or null if not found) for material from
 * routes configurations and their child routes.
 *
 * @param {String} materialType Material type
 * @param {Array} routes Route configuration array
 * @returns {Object|null} Route configuration or null if not found
 */
function findMaterialIndexObject(materialType, routes) {
  const parentRoutes = recurseFindMaterialRoute(materialType, routes)
  let parentRoute = null

  parentRoutes.forEach((route) => {
    if (route && route.children && !parentRoute) {
      parentRoute = route.children.find(item => item.name && item.name.endsWith(".index"))
    }
  })

  return parentRoute
}

/**
 * Find parent route of given route
 *
 * @param {Object} router VueRouter
 * @param {Object} childRoute Route which parent we want to find. Usually the current route
 */
function findParentRoute(router, childRoute) {
  const routes = router.options.routes
  let route = null

  for (const i in routes) {
    route = recurseFindParentRoute(routes[i], childRoute)
    if (route) {
      break
    }
  }

  return route
}

/**
 * Find route configuration of specific route
 *
 * @param {Object} route
 * @param {Array} routes
 */
function findRoute(route, routes) {
  return recurseFindNamedRoute(route.name, routes)
}

/**
 * Find route that ends with routeEndsWith string for material type within routes
 * array. Used to find for example document view route for statute consolidated.
 *
 * @param {String} routeEndsWith
 * @param {String} materialType
 * @param {Array} routes
 */
function findRouteEndsWidth(routeEndsWith, materialType, routes) {
  // TODO: Handle routing for document language when that is added
  if (typeof materialType === "undefined") {
    throw "[router.findRouteEndsWidth] Document has no materialType"
  }

  const materialSections = recurseFindMaterialRoute(materialType, routes)

  if (typeof materialSections === "undefined" || materialSections === null || materialSections.length === 0) {
    throw `[router.findRouteEndsWidth] No section with material type "${materialType}".`
  }

  let routeName

  materialSections.forEach((materialSection) => {
    if (materialSection.name && materialSection.name.endsWith(routeEndsWith)) {
      // Check if parent has name (the material only has document view route)
      routeName = materialSection.name
    }
    const documentRoute = materialSection.children instanceof Array
      ? materialSection.children.find(child => child.name && child.name.endsWith(routeEndsWith))
      : null

    if (documentRoute && documentRoute.name) {
      routeName = documentRoute.name
    }
  })
  if (!routeName) {
    throw `[router.findRouteEndsWidth] No route '${routeEndsWith}' defined for material (${materialType})`
  }

  return routeName
}

/**
 * Get translated strings for matched routes in reverse order
 * @param {Array} matchedRoutes List of routes that were matched to current URL
 */
function getBreadcrumbs(matchedRoutes) {
  // TODO: This should return section objects. When breadcrumbs is dislayed,
  // there needs to be possibility to show submenus (like in Edilex)
  const breadcrumbs = matchedRoutes
    .filter(route => route.meta && route.meta.title)
    .map(route => i18n.t(route.meta.title))

  return breadcrumbs.reverse()
}

function getPageTitle(matchedRoutes, currentSectionConfig) {
  // TODO: Some dynamic page titles that may need to be added:
  // - Archive: year in title
  // - Keywords: letter or word in title
  // - Subjects: subject text
  //
  // Should this be done with new meta data titleFormat and then titleProps
  // (string array) of properties used for format?
  if (matchedRoutes.length < 1) {
    return ""
  }

  const lastMatch = matchedRoutes[matchedRoutes.length - 1]
  const isDocumentView = typeof lastMatch.instances === "object"
    && typeof lastMatch.instances.default === "object"
    && typeof lastMatch.instances.default.document === "object"
  const isSubjectView = typeof lastMatch.instances === "object"
    && typeof lastMatch.instances.default === "object"
    && typeof lastMatch.instances.default.subjectName === "string"
    && lastMatch.instances.default.subjectName.length > 0

  const items = [i18n.t(matchedRoutes[0].meta.title)]
  let title

  if (isDocumentView) {
    const document = lastMatch.instances.default.document

    title = truncate(document.DocumentTitle, {
      length: editaConfig.pageTitle.documentTitleLength,
      omission: "...",
    })
    document.DocumentCaption
      ? items.push(`${title} ${document.DocumentCaption}`)
      : items.push(title)
  } else if (isSubjectView) {
    title = truncate(lastMatch.instances.default.subjectName, {
      length: editaConfig.pageTitle.documentTitleLength,
      omission: "...",
    })

    items.push(title)
  } else {
    for (let i = 1; i < matchedRoutes.length; i++) {
      // Show section index route in page title based on configuration
      if (matchedRoutes[i].name === currentSectionConfig.name
        && (!currentSectionConfig.path || currentSectionConfig.path.length === 0)
        && !editaConfig.pageTitle.showMaterialIndexInTitle
      ) {
        continue
      }
      if (matchedRoutes[i].meta && matchedRoutes[i].meta.title) {
        items.push(i18n.t(matchedRoutes[i].meta.title))
      }
    }
  }

  return items.filter(item => item).reverse().join(` ${editaConfig.pageTitle.separator} `)
}

/**
 * Make default section configuration for material
 * @param {Object} material Material configuration
 */
function makeDefaultMaterialRoute(material) {
  return {
    component: MaterialPageWrapper,
    props: {
      materialType: material.materialType instanceof Array
        ? material.materialType
        : [material.materialType],
      configuration: material.configuration,
    },
    meta: {
      title: material.title,
      description: material.description,
    },
  }
}

/**
 * Create a grouped route for multiple materials which can display one navigation
 * item active. For example statute consolidated and eu statute consolidated but
 * should highlight the same navigation item.
 *
 * There are two ways to use grouped route.
 * 1) all child routes are in their own routes (/kko)
 * 2) all child routes are under the group route (/oikeuskaytanto/kko)
 *
 * How to use 1)
 * - Provide no path for makeGroupRoute function
 * - Provide a path for material group route function (use default path configuration)
 *
 * How to use 2)
 * - Provide root path for group route in makeGroupRoute function
 * - Provide empty path to override material group
 *   See: client/common/router/routes/groups
 *
 * NOTE: The first item in childRoutes array will be used for navigation label
 *
 * @param {Array} childRoutes Array of different routes (for materials) under group
 * @param {Object} options
 *
 * options {
 *   @param {string} path Path prefix for all child routes
 *   @param {bool} keepOnlyDocumentView Remove unnecessary routes from all but first child ("group index")
 *
 * }
 */
function makeGroupRoute(childRoutes, {
  path = "", // Path prefix for all child routes
  keepOnlyDocumentView = false, // Remove all but document view from child routes (except first one)
} = {}) {
  if (keepOnlyDocumentView) {
    for (const index in childRoutes) {
      // Skip the first one because it is "main" route for group route. It should
      // always contain listing, archive and such routes.
      if (index > 0 && childRoutes[index].children) {
        childRoutes[index].children = childRoutes[index].children
          .filter(route => route.name && route.name.endsWith(".documentView"))
        // Make all other routes go to not found
        childRoutes[index].children.push({
          path: "",
          redirect: { name: "not-found" },
        })
        childRoutes[index].sidebarUseGroupSection = true
      }
    }
    // NOTE: Fix material types for "main" route jumpto material.
    // When multiple materials are grouped. We want allow jumpto to work for
    // all materials within the group (from "main" route) that have document
    // view when other materials only have document view (keepOnlyDocumentView = true)
    if (childRoutes.length > 1) {
      // NOTE: Index 0 = "main" route that contains the jumpto route used in sidebar.
      const jumpToRoute = childRoutes[0].children.find(r => r.name.endsWith(".jumpto"))

      if (jumpToRoute) {
        if (!jumpToRoute.props) {
          jumpToRoute.props = {}
        }

        jumpToRoute.props.materialType = uniq(flatMap(childRoutes
          .filter(route => route.props && route.props.materialType // Route should have material types
            // Only materials with document view should be included in jump to
            && route.children.find(childRoute => childRoute.name.endsWith(".documentView"))
          )
          .map(r => r.props.materialType)))
      }
    }
  }
  for (const index in childRoutes) {
    childRoutes[index].isPartOfGroup = true
  }

  return {
    path,
    component: GenericWrapper,
    children: childRoutes,
  }
}

function recurseFindMaterialRoute(materialType, routes) {
  let materialRoutes = routes.filter(item => item.props
    && item.props.materialType instanceof Array
    && item.props.materialType.length === 1 // Skip all group routes
    && item.props.materialType.includes(materialType)
  )

  if (materialRoutes.length > 0) {
    return materialRoutes
  } else {
    for (const item of routes) {
      materialRoutes = [
        ...materialRoutes,
        ...recurseFindMaterialRoute(materialType, item.children || []),
      ]
    }

    return materialRoutes
  }
}

function recurseFindNamedRoute(name, routes) {
  const find = routes.find(item => item.name === name)

  return find || routes.map(route => recurseFindNamedRoute(name, route.children || []))
    .filter(r => r) // Remove empty
    .pop() // Pop then found item; if not found returns undefined
}

function recurseFindParentRoute(route, childRoute) {
  if (!route.children) {
    return null
  }
  // TODO: Find a way to compare routes without using 'meta'. They cannot be
  // compared directly because they contain different data (config vs router option)
  if (route.children.find(subroute => subroute.meta === childRoute.meta)) {
    return route
  } else {
    return route.children.reduce((acc, subroute) => {
      if (acc.length === 0) {
        const result = recurseFindParentRoute(subroute, childRoute)

        if (result) {
          return acc.concat(result)
        } else {
          return acc
        }
      } else {
        return acc
      }
    }, []).pop() // Return the found result which is last of array
  }
}

export {
  addRouteNames,
  findMaterialIndexObject,
  findParentRoute,
  findRoute,
  findRouteEndsWidth,
  getBreadcrumbs,
  getPageTitle,
  makeDefaultMaterialRoute,
  makeGroupRoute,
}
