<template lang="html">
  <ContentContainer variant="archive">
    <SidebarMaterial
      :active="$route.name"
      :anchor="$route.hash"
      :advanced-search-route="advancedSearchRoute"
      :has-subsection="configuration.sidebar.subsection.enabled"
      :has-search="configuration.sidebar.search"
      :subsubsection="configuration.sidebar.subsection.subitems"
      :has-update-info="configuration.sidebar.updateInfo"
      :items="section.children"
      :material-type="materialType"
      :options-material-jump-to="configuration.sidebar.jumpToDocument"
      :section="section"
      :ui-language="uiLanguage"
      :update-info="updateInfo"
      @material-jump-to="storeMaterialJumpTo"
      @search-global="searchGlobal"
    />
    <MainContainer>
      <Heading
        v-if="heading"
        level="h1"
      >
        {{ $t(heading) }}
      </Heading>

      <BaseForm>
        <FieldsetCheckboxGroup
          v-if="materialFilters.length > 1"
          v-model="internalMaterialFilter"
          :disabled="loadingYears || loadingDocuments"
          legend="label.filter"
          orientation="horizontal"
        />
      </BaseForm>

      <Throbber v-if="loadingYears" />
      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <FeedbackMessage
        v-if="!loadingYears && years.length === 0 && hasMaterialsUiLanguage"
        variant="info"
      >
        {{ $t("label.archiveNoYears") }}
      </FeedbackMessage>
      <FeedbackMessage
        v-if="!loadingYears && years.length === 0 && !hasMaterialsUiLanguage"
        variant="info"
      >
        {{ $t("label.material.onlyDefaultLanguage") }}
        <BaseLink :to="defaultLanguageRoute">
          {{ $t("label.moveToDefaultLanguage") }}
        </BaseLink>
      </FeedbackMessage>
      <MainContentContainer v-if="years.length > 0">
        <ArchiveYears
          :show-document-count="true"
          :route-name="$route.name"
          :year="activeYear"
          :years="years"
          :ui-language="uiLanguage"
        />

        <ListPaged
          v-if="!error"
          :page="page"
          :page-count="pageCount"
          :show-bottom="!loadingDocuments"
          @go="goPage"
          @goFirst="firstPage"
          @goLast="lastPage"
          @goNext="nextPage"
          @goPrevious="previousPage"
        >
          <Throbber v-if="loadingDocuments" />
          <DocumentList
            v-if="!loadingDocuments && documents.length > 0"
            :configurations="configurations"
            :documents="documents"
            :image-src="imageSrc"
            :options-document-list-item="optionsDocumentListItem"
            :ui-language="uiLanguage"
            view="list"
            variant="annual-listing"
          />
          <FeedbackMessage
            v-if="!loadingDocuments && documents.length === 0"
            variant="info"
          >
            {{ $t("error.noDocuments") }}
          </FeedbackMessage>
        </ListPaged>
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script lang="js">
import { flatMap, isEqual } from "lodash"
import { mapActions } from "vuex"
import storeTypes from "@common/config/store-types"
import editaConfig from "@/edita.config.js"
import { handleError } from "./utils.js"
import DocumentBase from "./mixins/DocumentBase.vue"
import MaterialFilter from "./mixins/MaterialFilter.vue"
import Pagination from "./mixins/Pagination.vue"
import Sidebar from "./mixins/Sidebar.vue"

export default {
  name: "ArchiveWrapper",
  mixins: [
    DocumentBase,
    MaterialFilter,
    Pagination,
    Sidebar,
  ],
  props: {
    materialType: {
      type: Array,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
    /**
     * Unused
     */
    section: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
  },
  data() {
    return {
      documents: [],
      error: null,
      limit: 50, // Override Pagination mixin
      loadingDocuments: false,
      loadingYears: true,
      optionsDocumentListItem: editaConfig.DocumentListItem,
      requireLogin: false,
      years: [],
    }
  },
  computed: {
    activeYear() {
      const year = Math.max(parseInt(this.$route.params.year), 1)

      if (year) {
        return year
      } else if (this.years.length > 0) {
        return this.years[0].year
      } else {
        return null
      }
    },
    pageCount() {
      const selectedYear = this.activeYear && this.years
        ? this.years.find(year => year.year === this.activeYear)
        : null

      return selectedYear && selectedYear.documents
        ? Math.ceil(selectedYear.documents / this.limit)
        : 0
    },
  },
  watch: {
    // call again the method if the route changes
    "$route.params.year": "changeYear", // Update documents when year changes
    "years": "changeYear", // Update documents when years list changes
    "page": "getDocuments", // Update documents when page changes
    "uiLanguage": "getYears",
  },
  created() {
    this.getYears()
  },
  methods: {
    ...mapActions("document", {
      storeGetYears: storeTypes.GET_YEARS,
      storeMaterialJumpTo: storeTypes.MATERIAL_JUMP_TO,
      storeSearchDocuments: storeTypes.SEARCH_DOCUMENTS,
    }),
    changeYear(newYears, oldYears) {
      if (!isEqual(newYears, oldYears)) {
        this.firstPage()
        this.getDocuments()
      }
    },
    getDocuments() {
      const yearParam = this.$route.params.year
        ? Math.max(parseInt(this.$route.params.year), 1)
        : null
      const activeYear = this.years.length > 0
        ? this.years.find(y => y.year === yearParam || (!yearParam && y.year === this.years[0].year)).year
        : null
      // Either user specified year or use the latest year in document years

      if (!activeYear) {
        this.$set(this, "documents", [])

        return
      }

      this.loadingDocuments = true
      this.error = null

      let currentMaterialTypes = flatMap(this.materialFilters
        .filter(group => group.selected), group => group.materialTypes
      ).filter(material => material)

      if (currentMaterialTypes.length === 0) {
        currentMaterialTypes = this.materialType
      }
      const payload = {
        materialType: currentMaterialTypes,
        languages: [this.uiLanguage],
        year: this.activeYear,
        offset: this.page - 1,
        limit: this.limit,
        sort: "latestFirst",
      }

      this.storeSearchDocuments(payload)
        .then((response) => {
          this.loadingDocuments = false
          this.documents = response.documents
        })
        .catch((error) => {
          this.loadingDocuments = false
          if (error.response && error.response.status === 401) {
            this.requireLogin = true
          }
          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
    getYears() {
      this.loadingYears = true
      this.error = null
      // NOTE: Use Vue.set() because of reactivity issue with arrays
      this.$set(this, "years", [])

      let currentMaterialTypes = flatMap(this.materialFilters
        .filter(group => group.selected), group => group.materialTypes
      ).filter(material => material)

      if (currentMaterialTypes.length === 0) {
        currentMaterialTypes = this.materialType
      }

      const payload = {
        materialType: currentMaterialTypes,
        languages: [this.uiLanguage],
      }

      this.storeGetYears(payload)
        .then((response) => {
          // Use slice so we do not mutate the original in state
          // NOTE: Use Vue.set() because of reactivity issue with arrays
          this.$set(this, "years", response.years.slice().reverse())
          this.loadingYears = false
        })
        .catch((error) => {
          this.loadingYears = false
          if (error.response && error.response.status === 401) {
            this.requireLogin = true
          }

          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
    updateMaterialFilter() {
      this.getYears()
    },
    updateRoute() {}, // NOTE: This disables route changing that is on by default
  },
}
</script>
