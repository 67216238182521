import {
  CASE_LAW,
  LAW_DRAFTING,
  LEGISLATION,
  NEWS,
  TYOSUHDEASIOIDEN_KASIKIRJA,
} from "./material-group"

// Ordering here determines search facet ordering. Do NOT sort them alphabetically
// like everywhere else!
export default [
  {
    id: "all",
    label: "label.all",
    childGroups: [
      TYOSUHDEASIOIDEN_KASIKIRJA.id,
      NEWS.id,
      LEGISLATION.id,
      CASE_LAW.id,
      LAW_DRAFTING.id,
    ],
    selected: true,
  },
  TYOSUHDEASIOIDEN_KASIKIRJA,
  NEWS,
  LEGISLATION,
  CASE_LAW,
  LAW_DRAFTING,
]
