import { createRouter, whoamiMiddleware } from "@common/router"
import config from "@/edita.config.js"
import routes from "./routes"

const router = createRouter(config, routes)

// Use whoami middleware to do whoami call (check authentication) and then fetch
// static content for the application.
router.beforeEach((to, from, next) => whoamiMiddleware(router.app.$store)(to, from, next))

export default router
export { routes }
