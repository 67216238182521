import store from "@/store"
import axios from "./axios-instance"

export default {
  sendFeedback: payload => axios(store)({
    url: "/feedback",
    method: "POST",
    data: payload,
  }),
}
