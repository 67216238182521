// TODO: Handle material types
import store from "@/store"
import axios from "./axios-instance"

export default {
  getDocuments: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || !(payload.materialType instanceof Array)
      || typeof payload.language !== "string"
      || typeof payload.word !== "string"
    ) {
      reject(new Error("[keyword.getDocuments] Payload incorrect"))
    }
    const pagination = typeof payload.offset !== "undefined" || typeof payload.limit !== "undefined"
      ? {
        limit: payload.limit ? payload.limit : 10,
        offset: payload.offset ? payload.offset : 0,
      } : {}

    axios(store)({
      url: `/keyword/${payload.language}/word/${payload.word}`,
      method: "GET",
      params: {
        materialType: payload.materialType,
        ...pagination,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
  getLetters: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
    || !(payload.materialType instanceof Array)
      || typeof payload.language !== "string"
    ) {
      reject(new Error("[keyword.getLetters] Payload incorrect"))
    }
    axios(store)({
      url: `/keyword/${payload.language}`,
      method: "GET",
      params: { materialType: payload.materialType },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
  getKeywords: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || !(payload.materialType instanceof Array)
      || typeof payload.language !== "string"
      || typeof payload.letter !== "string"
    ) {
      reject(new Error("[keyword.getKeywords] Payload incorrect"))
    }
    const letter = payload.letter.toLowerCase()
    const pagination = typeof payload.offset !== "undefined" || typeof payload.limit !== "undefined"
      ? {
        limit: payload.limit ? payload.limit : 10,
        offset: payload.offset ? payload.offset : 0,
      } : {}
    const query = {
      materialType: payload.materialType,
      ...pagination,
    }

    axios(store)({
      url: `/keyword/${payload.language}/letter/${letter}`,
      method: "GET",
      params: query,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
  searchKeywords: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || typeof payload.text !== "string"
      || typeof payload.language !== "string"
    ) {
      reject(new Error("[keyword.searchKeywords] Payload incorrect"))
    }
    const text = encodeURIComponent(payload.text)

    axios(store)({
      url: `/keyword/${payload.language}/search/${text}`,
      method: "GET",
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
}
